import { AccountDetails } from '@src/api/accounts/types'

/**
 * This API `/routes/ta/get/account-details?id=X,Y,Z` returns a JSON with common account details.
 * Some of these details include Strategy name.
 *
 * Function `getStrategyName()` tries to return Strategy name or related identifiers
 *
 * @param {Object} singleAccountDetails
 * @return {String}
 */
const getStrategyName = (singleAccountDetails?: AccountDetails): string => {
  if (!singleAccountDetails) {
    return ''
  }

  const accountData = singleAccountDetails.book

  if (String(accountData.MoveStrategyType).toLocaleUpperCase() === 'LIQUIDATE') {
    return accountData.MoveAccountGroupId || 'Liquidated account'
  }

  return accountData.MoveStrategyName ||
         accountData.MoveStrategyType ||
         'TBD'
}

export default getStrategyName
