import axiosClient from '../axiosClient'
import validateResponse from '../validateResponse'

import {
  LoggedInUser,
  LoggedInUserRelationshipManager,
} from './types'


const routes = {
  getLoggedInUser: async (): Promise<LoggedInUser> => axiosClient
    .get('api/auth/okta/user')
    .then(validateResponse(LoggedInUser)),

  getLoggedInUserRelationshipManager: async (): Promise<LoggedInUserRelationshipManager> => {
    // return axiosClient
    //   .get('api/mandate/relationship-manager')
    //   .then(validateResponse(LoggedInUserRelationshipManager))

    /**
     * TODO: Consider taking this info from move-exodus
     */
    const relationshipManager: LoggedInUserRelationshipManager = {
      email: 'wealth.manager@movedigital.ch',
      phone: {
        countryCode: 41,
        nationalNumber: '044 503 5470',
      },
      address: {
        country: 'CH',
        canton: 'ZH',
        town: 'Zürich',
        streetName: 'Giesshübelstrasse',
        streetNumber: '40',
        zipCode: '8045',
      },
    }

    return Promise.resolve(relationshipManager)
  },
}


export default routes
