import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { Notification } from 'move-ui'

import { AxiosApiResponseError } from '@src/api/types'
import { DEFAULT_CURRENCY } from '@src/api/accounts/constants'

import routes from '../routes'
import { LoggedInUser } from '../types'


type BaseOptions = UseQueryOptions<LoggedInUser, AxiosApiResponseError>
type Options = BaseOptions & {
  onAfterSuccess?: BaseOptions['onSuccess']
  onAfterError?: BaseOptions['onError']
}

type BaseResult = UseQueryResult<LoggedInUser, AxiosApiResponseError>
type Result = BaseResult & {
  loggedInUser?: LoggedInUser
  loggedInUserUuid: string
  reportingCurrency: string
}


const useGetLoggedInUser = (
  options: Options = {},
): Result => {
  const queryResult = useQuery<LoggedInUser, AxiosApiResponseError>(
    ['getLoggedInUser'],
    routes.getLoggedInUser,
    {
      onSuccess: (...args) => {
        if (options.onAfterSuccess) {
          options.onAfterSuccess(...args)
        }
      },

      onError: (...args) => {
        const [error] = args
        const backEndErrorMessage = error.response?.data?.error || ''

        Notification.error({
          key: 'getLoggedInUser',
          message: `Loading error ${error.response?.statusText || ''}`,
          description: `We could not get your user info. ${backEndErrorMessage}`,
          duration: 7,
        })

        if (options.onAfterError) {
          options.onAfterError(...args)
        }
      },

      ...options,
    })

  return {
    ...queryResult,
    loggedInUser: queryResult.data,
    loggedInUserUuid: queryResult.data?.uuid || '',
    reportingCurrency: queryResult.data?.reportingCurrency || DEFAULT_CURRENCY,
  }
}


export default useGetLoggedInUser
