import * as jf from 'joiful'
import { AxiosError } from 'axios'

import { oneOfEnum, objectOfClass } from '@src/api/commonValidationDecorators'


export type AxiosApiResponseError = AxiosError<{
  description?: string
  message?: string
  error?: string
}>


export enum BrokerType {
  CREDIT_SUISSE = 'CS',
  INTERACTIVE_BROKERS = 'IB',
  LOMBARD_ODIER = 'LO',
  HSBC = 'HSBC',
  PAPER_TRADING = 'PAPER_TRADING',
  UNDER_SANCTIONS = 'UNDER_SANCTIONS',
}


export enum ProductAssetType {
  CASH = 'CASH',
  BOND = 'BOND',
  STOCK = 'STOCK',
  OPTION = 'OPTION',
  EQUITY = 'EQUITY',
  FXRATE = 'FXRATE',
  CURRENCY = 'CURRENCY',
  COMMODITY = 'COMMODITY',
}


export class Book {
  @jf.number().required()
  Id: number

  @jf.number().required()
  Version: number

  @jf.string().required()
  Code: string

  @jf.string().required()
  AccountType: string

  @jf.string().required()
  MoveAccountGroupId: string

  @oneOfEnum(BrokerType)
  MoveBrokerType: BrokerType

  @jf.string().required()
  MoveCurrency: string

  @jf.number().optional()
  MoveStrategyId?: number

  @jf.string().optional()
  MoveStrategyName?: string

  @jf.string().optional()
  MoveStrategyType?: string

  @jf.number().optional()
  MoveQuantStrategyId?: number

  @jf.string().uri().optional()
  MoveWiStreamUrl?: string

  @jf.string().uri().optional()
  MoveWiCompanyWatchListUrl?: string

  @jf.string().uri().optional()
  MoveWiProductWatchListUrl?: string

  @jf.string().optional()
  MoveTags?: string

  @jf.string().required()
  MoveOwnerId: string

  @jf.string().required()
  MoveAbbr: string

  @jf.array().items((joi) => joi.string()).required()
  MoveManagersById: string[]

  @jf.array().items((joi) => joi.string()).required()
  MoveManagersByAbbr: string[]

  @jf.date().required()
  MoveCreated: Date

  @jf.date().optional()
  MoveFunded?: Date

  @jf.date().optional()
  MoveClosed?: Date
}


export class Product {
  @jf.number().required()
  Id: number

  @jf.number().required()
  Version: number

  /* Bond products have empty `EquityName` but non-empty `Description` */
  @jf.string().allow('').required()
  EquityName: string

  @jf.string().required()
  QuoteName: string

  @jf.string().required()
  Description: string

  @jf.string().allow('').required()
  CorporateName: string

  @oneOfEnum(ProductAssetType)
  AssetType: ProductAssetType

  @jf.string().required()
  Currency: string

  /* ISIN can be empty when transaction is forex exchange, e.g. "FX USD:EUR|23/Feb/21" */
  @jf.string().allow('').required()
  ISIN: string

  @jf.string().allow('').required()
  MoveTicker: string

  @jf.string().allow('').required()
  MoveSecurityNexusId: string

  @jf.string().allow('').required()
  MoveDescription: string

  @jf.string().allow('').required()
  MWMAsset: string

  @jf.string().allow('').required()
  MWMRegion: string

  @jf.string().allow('').required()
  MWMSector: string
}


export class Transaction {
  @jf.number().required()
  id: number

  @objectOfClass(Book)
  book: Book

  @objectOfClass(Product)
  product: Product

  @jf.string().allow('').optional()
  MoveTradeId?: string

  @jf.string().allow('').optional()
  MoveEventId?: string

  @jf.string().allow('').optional()
  MoveExecutionId?: string

  @jf.number().required()
  quantity: number

  @jf.string().required()
  currency: string

  @jf.number().required()
  forexRate: number

  @jf.number().required()
  price: number

  @jf.number().required()
  marketValue: number

  @jf.date().required()
  date: Date
}
