import * as jf from 'joiful'


export class SaveErrorData {
  @jf.string().required()
  errorMessage: string
}


export class SaveErrorResponse {
  @jf.date().required()
  createdAt: Date
}
