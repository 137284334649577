import axiosClient from '../axiosClient'
import validateResponse from '../validateResponse'

import {
  LogoutResponse,
} from './types'


const routes = {
  logout: async (): Promise<LogoutResponse> => axiosClient
    .post('api/auth/okta/logout')
    .then(validateResponse(LogoutResponse)),
}


export default routes
