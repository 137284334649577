import React, { useMemo } from 'react'
import startOfYear from 'date-fns/startOfYear'

import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'
import useGetLoggedInUserAccounts from '@src/api/accounts/hooks/useGetLoggedInUserAccounts'
import useGetAccountDetails from '@src/api/accounts/hooks/useGetAccountDetails'
import useGetAccountCashTransfers from '@src/api/accounts/hooks/useGetAccountCashTransfers'

import roundToPoint from '@src/utils/roundToPoint'
import { getLatestInfoDate } from '@src/utils/latestInfoDate'


const jan1 = startOfYear(new Date())

type YearToDatePerformancePercentageProps = {
  className?: string
  accountIds?: string[]
}


const YearToDatePerformancePercentage = ({
  className,
  accountIds: inputAccountIds = [],
}: YearToDatePerformancePercentageProps) => {
  const { reportingCurrency, loggedInUserUuid } = useGetLoggedInUser()
  const { accountIds: userAccountIds } = useGetLoggedInUserAccounts()

  const accountIds = useMemo(() => (
    inputAccountIds
      .filter((accountId) => userAccountIds.includes(accountId))
      .sort()
  ), [inputAccountIds, userAccountIds])


  const latestInfoDate = useMemo(() => getLatestInfoDate(), [])

  const startDate = useMemo(() => (
    latestInfoDate.getTime() < jan1.getTime()
      ? startOfYear(latestInfoDate)
      : jan1
  ), [latestInfoDate])

  const endDate = useMemo(() => (
    latestInfoDate.getTime() < jan1.getTime()
      ? jan1
      : latestInfoDate
  ), [latestInfoDate])


  const {
    accountDetails: accountDetailsJan1,
    isLoading: isLoadingAccountDetailsJan1,
  } = useGetAccountDetails({
    customerUuid: loggedInUserUuid,
    accountIds,
    currency: reportingCurrency,
    asOfDate: startDate,
  }, {
    enabled: Boolean(accountIds.length && loggedInUserUuid && reportingCurrency),
  })


  const {
    accountDetails,
    isLoadingAccountDetails,
  } = useGetAccountDetails({
    customerUuid: loggedInUserUuid,
    accountIds,
    currency: reportingCurrency,
  }, {
    enabled: Boolean(accountIds.length && loggedInUserUuid && reportingCurrency),
  })


  const {
    accountCashTransfers,
    isLoadingAccountCashTransfers,
  } = useGetAccountCashTransfers({
    customerUuid: loggedInUserUuid,
    accountIds,
    startDate,
    endDate,
    currency: reportingCurrency,
  }, {
    enabled: Boolean(accountIds.length && reportingCurrency && loggedInUserUuid),
  })


  const totalCashTransfers = useMemo(() => (
    accountCashTransfers
      .map(({ quantity, marketValue }) => Math.sign(quantity) * marketValue)
      .reduce((sum, value) => sum + value, 0)
  ), [accountCashTransfers])


  const totalNAVJan1 = useMemo(() => {
    if (!accountDetailsJan1) {
      return 0
    }

    return accountDetailsJan1
      .reduce((sum, { quotes }) => {
        const total = quotes?.nav || 0
        return sum + total
      }, 0)
  }, [accountDetailsJan1])


  const totalNAV = useMemo(() => {
    if (!accountDetails) {
      return 0
    }

    return accountDetails
      .reduce((sum, { quotes }) => {
        const total = quotes?.nav || 0
        return sum + total
      }, 0)
  }, [accountDetails])


  const performanceYearToDatePercentageLabel = useMemo(() => {
    /**
     * Please know that this formula is related to the same formula
     * we use to calculate YTD Performance in "Month End Report":
     *   https://github.com/AmanaAdvisors/MWM-admin/blob/f21c3f7fa3aad824b47feca606ae1743273c0d9a/src/utils/monthlyReport/slides/addPerformanceTableSlide.ts#L83
     *   https://github.com/AmanaAdvisors/MWM-admin/blob/f21c3f7fa3aad824b47feca606ae1743273c0d9a/src/utils/monthlyReport/slides/addPerformanceTableSlide.ts#L163
     */
    const denominator = totalNAVJan1 + totalCashTransfers
    const performanceValue = totalNAV - denominator

    const performanceYearToDatePercentage = denominator
      ? performanceValue / denominator * 100
      : 0

    const sign = performanceYearToDatePercentage > 0 ? '+' : ''

    return sign + roundToPoint(performanceYearToDatePercentage) + '%'
  }, [totalNAV, totalNAVJan1, totalCashTransfers])


  const isLoading = isLoadingAccountDetailsJan1 ||
    isLoadingAccountDetails ||
    isLoadingAccountCashTransfers


  return (
    <div className={className}>
      <span>
        YTD
      </span>
      {' '}
      <span className='flex-1 font-semibold text-right'>
        {isLoading ? '' : performanceYearToDatePercentageLabel}
      </span>
    </div>
  )
}


export default YearToDatePerformancePercentage
