import React, { ReactNode } from 'react'

import { Icon } from '@src/components'

import colors from '@src/utils/tailwindColors'
import { error } from '@src/utils/validateEnvVars'

type ValidateEnvVarsProps = {
  children: ReactNode
}

/**
 * Make sure the application is running with expected ENV VARs
 */

const ValidateEnvVars = ({ children }: ValidateEnvVarsProps) => {
  if (!error) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <div className='p-3x-big'>
      <div className='flex justify-center bg-red-200 border border-red-400 rounded space-x-base mx-auto mb-3x-big p-large'>
        <Icon
          name='x-circle'
          size={32}
          color={colors['brand-chestnut']}
        />

        <div>
          You`re seeing this error duo to build issues with Environment Variables.
          <br/>
          For further support, please contact:
          {' '}
          <a
            className='font-semibold'
            href='mailto:support@movewm.ch'
            target='_blank'
            rel='noopener noreferrer'
          >
            support@movewm.ch
          </a>
        </div>
      </div>

      <h2 className='text-lg w-3/5 mx-auto mt-2 mb-5 pl-8'>
        Incorrect ENV VARs
      </h2>

      <table className='border-collapse table-auto w-3/5 text-sm mx-auto'>
        <thead>
          <tr>
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-gray-400 text-left w-1/2'>
              Name
            </th>
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-gray-400 text-left w-1/2'>
              Value
            </th>
            <th className='border-b font-medium p-4 pl-8 pt-0 pb-3 text-gray-400 text-left w-1/2'>
              Error
            </th>
          </tr>
        </thead>

        <tbody>
          {error.details.map(({ message, context }, index) => (
            <tr key={index}>
              <td className='border-b border-gray-100 p-4 pl-8 text-gray-500'>
                {context?.key}
              </td>
              <td className='border-b border-gray-100 p-4 pl-8 text-gray-500'>
                {context?.value}
              </td>
              <td className='border-b border-gray-100 p-4 pl-8 text-gray-500'>
                {message}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}


export default ValidateEnvVars
