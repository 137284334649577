import { useMutation, UseMutationResult, UseMutationOptions, useQueryClient } from 'react-query'
import { Notification } from 'move-ui'

import { AxiosApiResponseError } from '@src/api/types'

import routes from '../routes'
import {
  LogoutResponse,
} from '../types'

type BaseOptions = UseMutationOptions<LogoutResponse, AxiosApiResponseError>
type Options = BaseOptions & {
  onAfterSuccess?: BaseOptions['onSuccess']
  onAfterError?: BaseOptions['onError']
}

type BaseResult = UseMutationResult<LogoutResponse, AxiosApiResponseError, void, unknown>
type Result = BaseResult & {
  logout: BaseResult['mutate']
}


const useLogout = (
  options: Options = {},
): Result => {
  const queryClient = useQueryClient()

  const queryResult = useMutation<LogoutResponse, AxiosApiResponseError>(
    routes.logout,
    {
      onSuccess: (...args) => {
        Notification.success({
          key: 'logout',
          message: 'Logout',
          description: 'We hope you enjoyed the Move High Net Worth experience! See you soon',
        })

        /* Make sure we remove all logged in user data */
        queryClient.clear()

        if (options.onAfterSuccess) {
          options.onAfterSuccess(...args)
        }
      },

      onError: (...args) => {
        const [error] = args
        const backEndErrorMessage = error.response?.data?.error || ''

        Notification.error({
          key: 'logout',
          message: `Logout unsuccessful ${error.response?.statusText || ''}`,
          description: `We could not log you out. ${backEndErrorMessage}`,
          duration: 7,
        })

        if (options.onAfterError) {
          options.onAfterError(...args)
        }
      },

      ...options,
    })

  return {
    ...queryResult,
    logout: queryResult.mutate,
  }
}


export default useLogout
