/**
 * This function takes a TypeScript `enum` and
 * returns all of it's key-value props as a JSON `Object`
 *
 * @param {Object} enumInstance The `enum` we want to return as `Object`
 * @return {Object} Key-value pair of all `enumInstance` props
 */
const enumToObject = (enumInstance: object): {[key: string]: unknown} => Object
  .keys(enumInstance)
  .filter((key) => Number.isNaN(Number(key)))
  .reduce((object, key) => ({
    ...object,
    [key]: enumInstance[key],
  }), {})


export default enumToObject
