import React, { FunctionComponent, CSSProperties, ReactNode } from 'react'

type ClickableTileProps = {
  dataTestidSuffix?: string
  className?: string
  style?: CSSProperties
  onClick?: () => void
  disabled?: boolean
  children?: ReactNode
}


const ClickableTile: FunctionComponent<ClickableTileProps> = ({
  dataTestidSuffix,
  className,
  style,
  children,
  onClick,
  disabled = false,
  ...props
}) => (
  <div
    data-testid={`ClickableTile${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
    className={className}
    style={style}
    {...props}
  >
    <div
      className={`
        ${onClick ? (disabled ? 'cursor-not-allowed' : 'cursor-pointer') : ''}
        relative
        bg-brand-cultured
        border border-brand-white rounded-xl
        transition duration-500
        filter drop-shadow
        ${onClick ? 'hover:drop-shadow-xl hover:bg-brand-mystic hover:border-brand-navy-blue' : ''}
        ${disabled ? 'brightness-90' : ''}
        p-base
        h-full
      `}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </div>
  </div>
)


export default ClickableTile
