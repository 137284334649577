import { QueryClient } from 'react-query'
import { AxiosError } from 'axios'

import ApiResponseValidationError from '@src/api/ApiResponseValidationError'

/**
 * Sometimes the BackEnd throws an error when we make an API call.
 * By default, `react-query` will always retry the same API call a couple of times
 * before throwing a BackEnd error.
 * `PERMANENT_ERROR_CODES` is a list of all BackEnd Error response codes
 * which we know that make the BackEnd throw the same error over and over again
 * if we retry to make the API call using the same query arguments.
 */
const PERMANENT_ERROR_CODES = [301, 302, 400, 401, 403, 404, 500]
const MAX_ERROR_RETRIES = 3

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: parseInt(process.env.REACT_APP_QUERY_CACHE_TIME as string, 10),
      staleTime: parseInt(process.env.REACT_APP_QUERY_STATE_TIME as string, 10),

      /**
       * This function will tell if `react-query` should retry a failed BackEnd API call.
       *
       * @param {number} failureCount Which error attempt we'll retry
       * @param {AxiosError} error What was the latest BackEnd error
       * @return {boolean} Should we retry the same query again
       */
      retry: (failureCount, error) => {
        if (error instanceof ApiResponseValidationError) {
          return false
        }

        const axiosError = error as AxiosError
        if (PERMANENT_ERROR_CODES.includes(axiosError.response?.status || 0)) {
          return false
        }

        return failureCount <= MAX_ERROR_RETRIES
      },
    },
  },
})

export default queryClient
