import React, { useMemo, useState } from 'react'
import { Modal } from 'antd'
import moment from 'moment'
import addDays from 'date-fns/addDays'
import { Button, DatePickerGrey } from 'move-ui'
import { timeService } from 'move-ui/lib/utils/time.service'

import { getDefaultLatestInfoDate, getOldestPossibleInfoDate, getLatestInfoDate } from '@src/utils/latestInfoDate'

type SetLatestInfoDateModalProps = {
  visible?: boolean
  onConfirm?: (date: Date) => void
  onClose?: () => void
  isLoading?: boolean
}


const SetLatestInfoDateModal = ({
  visible = false,
  onConfirm,
  onClose,
  isLoading = false,
}: SetLatestInfoDateModalProps) => {
  const [date, setDate] = useState(getLatestInfoDate())

  const oldestPossibleInfoDate = useMemo(() => getOldestPossibleInfoDate(), [])
  const nestPossibleInfoDate = useMemo(() => addDays(getDefaultLatestInfoDate(), 1), [])

  return (
    /* @ts-ignore */
    <Modal
      className='testid_SetLatestInfoDateModal'
      title='Set App info date'
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <p className='pb-base'>
        App should show data as of &quot;{timeService.getDateFormat(date)}&quot;:
      </p>

      <DatePickerGrey
        value={moment(date)}
        disabledDate={(date) => !date.isBetween(oldestPossibleInfoDate, nestPossibleInfoDate)}
        // @ts-ignore `newDateString` is expected to be of type `Moment` but it is `String`
        onChange={(newDateString) => newDateString ? setDate(new Date(newDateString)) : null}
        disabled={isLoading}
      />

      <div className='flex justify-end space-x-base mt-base px-base'>
        <Button
          data-testid='SetLatestInfoDateModal.CancelButton'
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          data-testid='SetLatestInfoDateModal.ConfirmButton'
          type='primary'
          onClick={() => onConfirm ? onConfirm(date) : null}
          disabled={isLoading}
          loading={isLoading}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}


export default SetLatestInfoDateModal
