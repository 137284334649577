import { useQuery, UseQueryOptions, UseQueryResult, useQueryClient } from 'react-query'
import { Notification } from 'move-ui'

import { AxiosApiResponseError } from '@src/api/types'

import routes from '../routes'
import { AccountNavParams, AccountNav } from '../types'


type BaseOptions = UseQueryOptions<AccountNav[], AxiosApiResponseError>
type Options = BaseOptions & {
  onAfterSuccess?: BaseOptions['onSuccess']
  onAfterError?: BaseOptions['onError']
}

type BaseResult = UseQueryResult<AccountNav[], AxiosApiResponseError>
type Result = BaseResult & {
  accountNavs: AccountNav[] | undefined
}


const useGetAccountNav = (
  params: AccountNavParams,
  options: Options = {},
): Result => {
  const queryClient = useQueryClient()

  const queryResult = useQuery<AccountNav[], AxiosApiResponseError>(
    ['getAccountNAV', params],
    () => routes.getAccountNav(params),
    {
      onSuccess: (...args) => {
        const [data] = args

        /**
         * Whenever we receive data for multiple accounts
         * we'll cache each individual account data
         * so queries for individual accounts will show that data immediately.
         */
        if (params.accountIds.length > 1) {
          params.accountIds.forEach((accountId, index) => {
            const singleAccountParams = {
              ...params,
              accountIds: [accountId],
            }

            const singleAccount = data.find((record) => record.accountId === accountId)
            if (singleAccount) {
              queryClient.setQueryData(
                ['getAccountNAV', singleAccountParams],
                [singleAccount],
              )
            }
          })
        }

        if (options.onAfterSuccess) {
          options.onAfterSuccess(...args)
        }
      },

      onError: (...args) => {
        const [error] = args
        const backEndErrorMessage = error.response?.data?.error || ''

        Notification.error({
          key: 'getAccountNAV',
          message: `Loading error ${error.response?.statusText || ''}`,
          description: `We could not get Net Asset Value. ${backEndErrorMessage}`,
          duration: 7,
        })

        if (options.onAfterError) {
          options.onAfterError(...args)
        }
      },

      /* We can safely cache Accounts NAV data for longer than usual since it's based on historical records that do not commonly change */
      cacheTime: 30 * 60 * 1000,
      staleTime: 30 * 60 * 1000,

      ...options,
    })


  return {
    ...queryResult,
    accountNavs: queryResult.data,
  }
}


export default useGetAccountNav
