import axiosClient from '../axiosClient'
import validateResponse from '../validateResponse'

import {
  SaveErrorData,
  SaveErrorResponse,
} from './types'


const routes = {
  saveError: async (
    data: SaveErrorData,
  ): Promise<SaveErrorResponse> => axiosClient
    .post('api/v1/errors', data)
    /**
     * TODO: Remove the mock below once we have a confirmed the API
     */
    .then(() => ({
      data: {
        createdAt: new Date().toISOString(),
      },
      status: 200,
      statusText: 'OK',
      headers: {},
      config: {},
    }))
    // @ts-ignore
    .then(validateResponse(SaveErrorResponse)),
}


export default routes
