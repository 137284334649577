/**
 * Makes sure we use only React keys for our HTML elements.
 * Example:
 *   convertHtmlKeyToReactKey('fill-rule')  =>  'fillRule'
 *
 * @param {String} key HTML key, e.g. 'fill-rule'
 * @return {String} React key, e.g. 'fillRule'
 */
const convertHtmlKeyToReactKey = (key = '') => (
  String(key || '')
    .split('-')
    .map((word, index) => {
      if (!index) {
        return word
      }

      return word[0].toLocaleUpperCase() + word.substring(1)
    })
    .join('')
)

export default convertHtmlKeyToReactKey
