import React, { useMemo } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'


const Devtools = () => {
  const reactQueryDevtoolsIsVisible = useMemo(() => Boolean(
    window.localStorage.getItem('reactQueryDevtoolsIsVisible'),
  ), [])

  return (
    <div
      data-testid='devtools'
      className={reactQueryDevtoolsIsVisible ? '' : 'hidden'}
    >
      <ReactQueryDevtools
        position='bottom-right'
        initialIsOpen={false}
      />
    </div>
  )
}


export default Devtools
