import * as jf from 'joiful'
import { getJoiSchema } from 'joiful/core'

import enumToObject from '@src/utils/enumToObject'


export const arrayOfClass = (Class) => jf.array({ elementClass: Class }).required()


export const arrayOfClassOptional = (Class) => jf.array({ elementClass: Class }).optional()


export const objectOfClass = (Class) => jf.object({ objectClass: Class }).required()


export const objectOfClassOptional = (Class) => jf.object({ objectClass: Class }).optional()


export const arrayOfEnum = (enumProp) => jf.array().items((joi) => joi.string()
  .valid( ...Object.values(enumToObject(enumProp)) )).required()


export const dictionaryOfClass = (Class) => jf.object()
  .custom(({ schema, joi }) => schema
    // @ts-ignore
    .pattern(/.+/, getJoiSchema(Class, joi)))


export const dictionaryOfPrimitive = (primitive) => jf.object()
  .custom(({ schema, joi }) => schema
    // @ts-ignore
    .pattern(/.+/, joi[primitive]()))


export const oneOfEnum = (enumProp) => jf.string()
  .valid(
    // @ts-ignore
    ...Object.values(enumToObject(enumProp)),
  )
  .required()


export const oneOfEnumOptional = (enumProp) => jf.string()
  .valid(
    // @ts-ignore
    ...Object.values(enumToObject(enumProp)),
  )
  .optional()
