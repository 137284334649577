import React from 'react'
import { Tag } from 'move-ui'

import { BrokerType } from '@src/api/types'
import colors from '@src/utils/tailwindColors'


const defaultColor = colors['brand-alto']

const brokerTypeToColor = {
  [BrokerType.CREDIT_SUISSE]: colors['brand-navy-blue'],
  [BrokerType.INTERACTIVE_BROKERS]: colors['brand-salmon'],
  [BrokerType.LOMBARD_ODIER]: colors['brand-silver'],
  [BrokerType.HSBC]: colors['brand-chestnut'],
  [BrokerType.PAPER_TRADING]: colors['brand-move-blue'],
  [BrokerType.UNDER_SANCTIONS]: colors['brand-valencia'],
}

const getBrokerTypeColor = (brokerType = ''): string => brokerTypeToColor[brokerType] || defaultColor


const defaultLabel = 'N/A'

const brokerTypeToLabel = {
  [BrokerType.CREDIT_SUISSE]: 'Credit Suisse',
  [BrokerType.INTERACTIVE_BROKERS]: 'Interactive Brokers',
  [BrokerType.LOMBARD_ODIER]: 'Lombard Odier',
  [BrokerType.HSBC]: 'HSBC',
  [BrokerType.PAPER_TRADING]: 'Paper Trading',
  [BrokerType.UNDER_SANCTIONS]: 'Sanctions',
}

const getBrokerTypeLabel = (brokerType = ''): string => brokerTypeToLabel[brokerType] || brokerType || defaultLabel


type BrokerTypeTagProps = {
  brokerType?: BrokerType
}


const BrokerTypeTag = ({ brokerType }: BrokerTypeTagProps) => (
  <Tag color={getBrokerTypeColor(brokerType)}>
    <div className='text-sm'>
      {getBrokerTypeLabel(brokerType)}
    </div>
  </Tag>
)


export default BrokerTypeTag
