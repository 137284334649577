import { useMemo } from 'react'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { Notification } from 'move-ui'

import { AxiosApiResponseError } from '@src/api/types'

import routes from '../routes'
import { LoggedInUserRelationshipManager } from '../types'


type BaseOptions = UseQueryOptions<LoggedInUserRelationshipManager, AxiosApiResponseError>
type Options = BaseOptions & {
  onAfterSuccess?: BaseOptions['onSuccess']
  onAfterError?: BaseOptions['onError']
}

type BaseResult = UseQueryResult<LoggedInUserRelationshipManager, AxiosApiResponseError>
type Result = BaseResult & {
  loggedInUserRelationshipManager: BaseResult['data']
  relationshipManagerPhoneNumber: string
  relationshipManagerEmail: string
}


const useGetLoggedInUserRelationshipManager = (
  options: Options = {},
): Result => {
  const queryResult = useQuery<LoggedInUserRelationshipManager, AxiosApiResponseError>(
    ['getLoggedInUserRelationshipManager'],
    routes.getLoggedInUserRelationshipManager,
    {
      onSuccess: (...args) => {
        if (options.onAfterSuccess) {
          options.onAfterSuccess(...args)
        }
      },

      onError: (...args) => {
        const [error] = args
        const backEndErrorMessage = error.response?.data?.error || ''

        Notification.error({
          key: 'getLoggedInUserRelationshipManager',
          message: `Loading error ${error.response?.statusText || ''}`,
          description: `We could not get your relationship manager. ${backEndErrorMessage}`,
          duration: 7,
        })

        if (options.onAfterError) {
          options.onAfterError(...args)
        }
      },

      ...options,
    })


  const relationshipManagerPhoneNumber = useMemo(() => {
    if (!queryResult.data) {
      return ''
    }

    /**
     * Make sure we show phone number as
     *   +41 762 474 726 and not as
     *   +41 0762 474 726
     */
    return `+${queryResult.data.phone.countryCode} ${queryResult.data.phone.nationalNumber.replace(/^0*/, '')}`
  }, [queryResult.data])


  return {
    ...queryResult,
    loggedInUserRelationshipManager: queryResult.data,
    relationshipManagerPhoneNumber,
    relationshipManagerEmail: queryResult.data?.email || '',
  }
}


export default useGetLoggedInUserRelationshipManager
