import React from 'react'
import { Link } from 'react-router-dom'

import { Icon } from '@src/components'


const PlatformDescription = ({ className }) => (
  <div className={className}>
    <Link
      className='inline-block'
      to='/client'
    >
      <Icon
        name='move-digital'
        size={100}
        marginTop={-24}
      />

      <div className='text-xs font-bold uppercase whitespace-nowrap -mt-big mb-medium'>
        <span>
          Wealth Management
        </span>
        {' | '}
        <span className='text-brand-navy-blue'>
          HNW
        </span>
      </div>
    </Link>
  </div>
)


export default PlatformDescription
