import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'
import DelayedFallback from './DelayedFallback'

/**
 * This wrapper helps with lazy loading of page,
 * encapsulates errors, and
 * shows a Loading element within a respectful delay.
 *
 * @return {object} React element
 */
const LoadableRoute = () => (
  <ErrorBoundary>
    <Suspense fallback={<DelayedFallback/>}>
      <Outlet/>
    </Suspense>
  </ErrorBoundary>
)

export default LoadableRoute
