import React, { useCallback, useMemo } from 'react'
import { Dropdown, Menu } from 'move-ui'

import { Icon } from '@src/components'

import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'
import useLogout from '@src/api/logout/hooks/useLogout'

import colors from '@src/utils/tailwindColors'


const { Item } = Menu


const AvatarMenu = ({ className }) => {
  const { loggedInUser } = useGetLoggedInUser()

  const avatarAbbreviation = useMemo(() => {
    if (!loggedInUser?.abbr) {
      return ''
    }

    /* Take only the first 2 letters */
    return loggedInUser.abbr
      .substring(0, 2)
      .toLocaleUpperCase()
  }, [loggedInUser])


  const { logout } = useLogout()
  const onClickLogout = useCallback(() => logout(), [logout])


  return (
    <div className={className}>
      {/* @ts-ignore */}
      <Dropdown
        className='cursor-pointer'
        overlay={(
          <Menu>
            <Item
              key='logOut'
              onClick={onClickLogout}
            >
              <div className='flex space-x-tiny items-center whitespace-nowrap'>
                <Icon
                  name='logout'
                  size={12}
                />
                <div>
                  Logout
                </div>
              </div>
            </Item>
          </Menu>
        )}
      >
        <div className='flex'>
          <div className={`
            flex items-center justify-center
            border border-brand-move-blue rounded-full
            text-xl text-brand-move-blue font-semibold
            w-12 h-12
            mr-small p-small
          `}>
            {avatarAbbreviation}
          </div>

          <Icon
            name='angle-down'
            size={20}
            color={colors['brand-silver']}
          />
        </div>
      </Dropdown>
    </div>
  )
}


export default AvatarMenu
