import React from 'react'
import { Table } from 'antd'

import { TableProps } from 'antd/lib/table/Table'

import styles from './TableGrey.module.scss'


const TableGrey = (props: TableProps<object>) => (
  <div className={styles.wrap}>
    <Table {...props}/>
  </div>
)


export default TableGrey
