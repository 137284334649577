/**
 * This file makes sure that our application always runs by using expected ENV VARs.
 * File is written using JavaScript (instead of TypeScript) base file is used in `craco.config.js`.
 * File is used in `craco.config.js` in order to make sure that builds use expected ENV VARs.
 */

const Joi = require('joi')

const envVarSchema = {
  REACT_APP_PROXY_API_SERVER: Joi
    .string()
    .uri()
    .required(),

  REACT_APP_OKTA_URL: Joi
    .string()
    .uri()
    .required(),

  REACT_APP_QUERY_CACHE_TIME: Joi
    .number()
    .integer()
    .positive()
    .max(30 * 24 * 60 * 60 * 1000)
    .optional()
    .default(5 * 60 * 1000),

  REACT_APP_QUERY_STATE_TIME: Joi
    .number()
    .integer()
    .positive()
    .max(30 * 24 * 60 * 60 * 1000)
    .optional()
    .default(5 * 60 * 1000),

  REACT_APP_TAG_NAME: Joi
    .string()
    .required(),
}


const { error } = Joi.object()
  .keys(envVarSchema)
  .required()
  .validate(process.env, {
    abortEarly: false,
    allowUnknown: true, // We need to allow unknown ENV VARs
                        // since Webpack is using some custom ENV VARs such as `FAST_REFRESH`
  })


const validateEnvVars = () => {
  if (error) {
    throw new ReferenceError(`Invalid ENV VAR:
  ${error.details
      .map(({ message, context }) => `  ${message}; currently ${context?.key}=${context?.value}`)
      .join('\n')}
  \n`)
  }
}


validateEnvVars.error = error

module.exports = validateEnvVars
