const colors = {
  /**
   * MoveUI default brand colors
   */
  'brand-white': '#fff',
  'brand-alabaster': '#f7f7f7',
  'brand-gallery': '#eeeeee',
  'brand-alto': '#ddd',
  'brand-silver': '#bbb',
  'brand-dull': '#888',
  'brand-mine_shaft': '#333',
  'brand-black': '#000',
  'brand-green_haze': '#00b22d',
  'brand-surf-green': '#119476',
  'brand-wasabi': '#86902D',
  'brand-mustard': '#FFD657',
  'brand-whiskey': '#D29E61',
  'brand-salmon': '#FF8173',
  'brand-linen': '#FDF5F5',
  'brand-valencia': '#d93a39',
  'brand-el-salva': '#833131',
  'brand-chestnut': '#B14C4B',
  'brand-cerise': '#CC2C9A',
  'brand-rose': '#FD0069',
  'brand-mystic': '#E7ECEF',
  'brand-pacific-blue': '#2b9fee',
  'brand-jordy-blue': '#9FC7F7',
  'brand-egg-blue': '#00BDC2',
  'brand-move-blue': '#34617C',
  'brand-blue-dianne': '#244457',
  'brand-navy-blue': '#0c5e96',
  'brand-geyser': '#d9e1e5',
  'brand-periglacial_blue': '#e6e8d8',
  'brand-bizarre': '#eddddd',
  'brand-merino': '#f3eae1',
  'brand-nobel': '#c4c4c4',
  'brand-dove-gray': '#737373',
  'brand-lotus': '#803736',

  /**
   * MoveUI dark brand colors
   */
  'brand-san-marino': '#4975A6',
  'brand-danube': '#629FE0',
  'brand-ghost': '#C7D0D9',
  'brand-rolling-stone': '#7C848C',
  'brand-abbey': '#555B61',
  'brand-fiord': '#44505C',
  'brand-outer-space': '#2A2F33',
  'brand-closer-to-home': '#363B40',
  'brand-shark': '#1f2326',
  'brand-friendly-shark': '#1B1F21',
  'brand-contessa': '#C27373',
  'brand-cape-code': '#2F3438',
  'brand-regent': '#91B7DF',
  'brand-shuttle-gray': '#596066',
  'brand-silver-sand': '#C7CACC',
  'brand-limed-spruce': '#3D4752',
  'brand-regent-grey': '#929CA6',
  'brand-raven': '#76818C',
  'brand-wedgewood': '#5990B6',
  'brand-bay-leaf': '#7DB28A',
  'brand-teak': '#B69166',
  'brand-geraldine': '#E48F8F',
  'brand-malibu': '#7ABAFF',
  'brand-tundora': '#4A4347',
  'brand-woodsmoke': '#101314',
  'brand-roman': '#CD6A6A',
  'brand-ferra': '#7B4F4F',
  'brand-grey-chateau': '#9BA5B0',
  'brand-mako': '#40474C',

  /**
   * Colors below are used only in this particular FrontEnd app
   * and are not part of the MoveUI library
   */
  'brand-foam': '#E7F4FD',
  'brand-ashy-blond': '#E7ECEFAA',
  'brand-dusty-gray': '#999',
  'brand-iron': '#E4E6E7',
  'brand-black-haze': '#F6F7F7',
  'brand-cultured': '#F6F7F7',
}

module.exports = colors
