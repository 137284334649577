import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const AUTO_RELOAD_TIME_IN_SECONDS = 10
let reloadTimer = null

/**
 * Contain all loading and execution errors that may happen to a Component.
 */
class ErrorBoundary extends Component {
  /**
   * @param {object} props Initiate this class with sent `props`
   */
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  /**
   * @param {error} error Thrown error from the child React element
   * @return {object} New component state with the thrown `error`
   */
  static getDerivedStateFromError(error) {
    if (typeof this?.props?.onError === 'function') {
      this.props.onError(error)
    }

    return { error }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.setState({ error: null })
      clearTimeout(reloadTimer)
    }
  }

  /**
   * @return {object} React element representing the Error message or safe to render `children`
   */
  render() {
    const { error } = this.state
    const { children } = this.props
    const retry = () => this.setState({ error: null })

    if (error) {
      /**
       * The if-statement below covers this scenario:
       *   User logs in.
       *   User's browser caches 5 from total of 10 pages.
       *   Then we make a deployment and replace all pages, i.e. `135.166bd438.chunk.js` no longer exists.
       *   When user tries to load a new (non-cached) page - he'll see this error
       *   `ChunkLoadError: Loading chunk 135 failed. (error https://wm.movedigital.solutions/static/js/135.166bd438.chunk.js)`
       *
       * In order to prevent loading errors
       * when user uses the app in between deployments
       * we'll check when `ChunkLoadError` is been thrown
       * and force the browser to reload the page from scratch - this will reload `https://wm.movedigital.solutions/index.html` and of its chinks.
       */
      if (String(error.stack || '').startsWith('ChunkLoadError') ||
          String(error.stack || '').startsWith('Error: Loading CSS chunk')
      ) {
        /**
         * Deployment may take up to a couple of minutes.
         * If we immediately reload- we'll risk to put the user`s browser in infinite loop
         * until deployment is fully completed.
         */
        clearTimeout(reloadTimer)
        reloadTimer = setTimeout(() => window.location.reload(), AUTO_RELOAD_TIME_IN_SECONDS * 1000)

        return (
          <div className='bg-brand-geraldine border border-brand-valencia rounded p-large'>
            We`re in the middle of deployment at the moment.
            <br />
            Your new page will automatically reload in <b>{AUTO_RELOAD_TIME_IN_SECONDS} secs</b>.
          </div>
        )
      }

      return (
        <div className='bg-brand-geraldine border border-brand-valencia rounded p-large'>
          <div>
            We`re unable to load the page at the moment:
          </div>

          <div className='font-semibold my-base'>
            {String(error || error?.message)}
          </div>

          <button
            className={`
              border border-brand-valencia rounded
              cursor-pointer font-semibold
              hover:underline
              mr-small px-base py-small
            `}
            type='button'
            onClick={retry}
          >
            Retry
          </button>
          or try again later
        </div>
      )
    }

    return children
  }
}


ErrorBoundary.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
}

ErrorBoundary.defaultProps = {
  children: '',
  onError: () => undefined,
}

ErrorBoundary.displayName = 'ErrorBoundary'


const ErrorBoundaryWrap = ({ ...props }) => {
  const location = useLocation()

  return (
    <ErrorBoundary
      { ...props }
      location={location}
    />
  )
}

export default ErrorBoundaryWrap
