import type { SvgObjects } from './types'
import convertHtmlKeyToReactKey from './convertHtmlKeyToReactKey'

const parser = new DOMParser()

/**
 * Returns all paths, rects, and their attributes for an SVG
 *
 * @param {String} svgText SVG text code
 * @return {Object} {"paths": string[], "rects": {[key: string]: string}[], "attrs": {[key: string]: string}[], "defsInnerHtml": ""}
 */
const getSvgObjects = (svgText = ''): SvgObjects => {
  const paths: string[] = []
  const rects: object[] = []
  const attrs: object[] = []

  const doc = parser.parseFromString(svgText, 'application/xml')

  doc
    .querySelectorAll('path')
    .forEach((path) => {
      const pathAttributes = {}

      path.getAttributeNames()
        .forEach((attributeName) => {
          const attribute = path.getAttribute(attributeName)

          if (attributeName === 'd') {
            if (attribute) {
              paths.push(attribute)
            }
          } else {
            pathAttributes[convertHtmlKeyToReactKey(attributeName)] = attribute
          }
        })

      attrs.push(pathAttributes)
    })


  doc
    .querySelectorAll('rect')
    .forEach((rect) => {
      const attributes = {}

      rect.getAttributeNames()
        .forEach((attributeName) => {
          const attribute = rect.getAttribute(attributeName)
          attributes[convertHtmlKeyToReactKey(attributeName)] = attribute
        })

      if (Object.keys(attributes).length) {
        rects.push(attributes)
      }
    })


  const defsInnerHtml = doc.querySelector('defs')?.innerHTML || ''


  return {
    paths,
    rects,
    attrs,
    defsInnerHtml,
  }
}


export default getSvgObjects
