import * as jf from 'joiful'

import { BrokerType } from '@src/api/types'

import {
  arrayOfClass,
  arrayOfClassOptional,

  objectOfClass,
  objectOfClassOptional,

  oneOfEnum,
} from '@src/api/commonValidationDecorators'

import { Book, ProductAssetType } from '@src/api/types'


export enum AccountType {
  BROKER_ACCOUNT = 'BROKER_ACCOUNT',
  CASH_ACCOUNT = 'CASH_ACCOUNT',
}


export class LoggedInUserAccountsParams {
  @jf.string().required()
  uuid: string
}


export class Account {
  @jf.number().integer().positive().required()
  Id: number

  @jf.number().integer().positive().required()
  Version: number

  @jf.string().required()
  Code: string

  @oneOfEnum(AccountType)
  AccountType: AccountType

  @jf.string().required()
  MoveAccountGroupId: string

  @oneOfEnum(BrokerType)
  MoveBrokerType: BrokerType

  @jf.string().required()
  MoveCurrency: string

  @jf.number().optional()
  MoveStrategyId?: number

  @jf.string().optional()
  MoveStrategyName?: string

  @jf.string().optional()
  MoveTags?: string

  @jf.string().allow('').required()
  MoveOwnerId: string

  @jf.string().allow('').required()
  MoveAbbr: string

  @jf.date().optional()
  MoveFunded?: Date

  @jf.date().required()
  MoveCreated: Date
}


export class AccountNavParams {
  @jf.string().required()
  customerUuid: string

  @jf.array().items((joi) => joi.string()).required()
  accountIds: string[]

  @jf.date().required()
  fromDate: Date

  @jf.date().required()
  toDate: Date

  @jf.string().optional()
  currency?: string
}


export class AccountNav {
  @jf.string().required()
  accountId: string

  @jf.number().required()
  fromNav: number

  @jf.number().required()
  toNav: number

  @jf.array().required()
  timeseries: [string, number][]
}


export class AccountDetailsParams {
  @jf.string().required()
  customerUuid: string

  @jf.array().items((joi) => joi.string()).required()
  accountIds: string[]

  @jf.date().optional()
  asOfDate?: Date

  @jf.string().optional()
  currency?: string
}


class AccountDetailsQuotes {
  @jf.number().required()
  nav: number

  @jf.number().required()
  cash: number

  @jf.date().required()
  cashDate: Date

  @jf.date().required()
  navDate: Date

  @jf.date().required()
  reqDate: Date
}


export class AccountDetailsPosition {
  @jf.number().required()
  id: number

  @jf.string().required()
  name: string

  @jf.string().allow('').required()
  isin: string

  @jf.string().allow('').required()
  ticker: string

  @jf.number().integer().min(0).required()
  securityNexusId: number

  @jf.string().required()
  ccy: string

  @oneOfEnum(ProductAssetType)
  productAssetType: ProductAssetType

  @jf.string().allow('').required()
  MWMAsset: string

  @jf.string().allow('').required()
  MWMRegion: string

  @jf.string().allow('').required()
  MWMSector: string

  @jf.number().required()
  fx: number

  @jf.number().required()
  mv: number

  @jf.number().required()
  px: number

  @jf.number().required()
  qty: number
}


export class AccountDetails {
  @objectOfClass(Book)
  book: Book

  @objectOfClass(AccountDetailsQuotes)
  quotes: AccountDetailsQuotes

  @arrayOfClass(AccountDetailsPosition)
  positions: AccountDetailsPosition[]

  /**
   * We may have requested data for a specific date
   * but `positions` might be available only for a past date.
   */
  @jf.date().required()
  positionsDate: Date
}


export class AccountTransactionsParams {
  @jf.string().required()
  customerUuid: string

  @jf.string().required()
  accountId: string

  @jf.date().required()
  fromDate: Date

  @jf.date().required()
  toDate: Date

  @jf.string().optional()
  currency?: string

  @jf.number().integer().min(0).optional()
  maximumTransactions?: number

  @jf.boolean().optional()
  shouldIncludeCash?: boolean
}


export class AccountCashTransfersParams {
  @jf.string().required()
  customerUuid: string

  @jf.array().items((joi) => joi.string()).required()
  accountIds: string[]

  @jf.date().required()
  startDate: Date

  @jf.date().required()
  endDate: Date

  @jf.string().optional()
  currency?: string
}


export class AccountHistoricalAllocationParams {
  @jf.string().required()
  customerUuid: string

  @jf.string().required()
  accountId: string

  @jf.date().required()
  fromDate: Date

  @jf.date().required()
  toDate: Date

  @jf.string().optional()
  currency?: string
}


export class AccountHistoricalAllocationCashData {
  @jf.number().required()
  cash: number

  @jf.string().required()
  currency: string

  @jf.string().required()
  forexCurrency: string

  @jf.number().required()
  forexRate: number

  @jf.date().required()
  resultDate: Date
}


export class AccountHistoricalAllocationPositionData {
  @arrayOfClassOptional(AccountDetailsPosition)
  positions: AccountDetailsPosition[]

  @jf.date().required()
  positionsDate: Date
}


export class AccountHistoricalAllocation {
  @jf.date().required()
  requestedDate: Date

  @objectOfClassOptional(AccountHistoricalAllocationCashData)
  cashData: AccountHistoricalAllocationCashData

  @objectOfClassOptional(AccountHistoricalAllocationPositionData)
  positionsData: AccountHistoricalAllocationPositionData
}
