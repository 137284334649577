import React, { LegacyRef } from 'react'
import { Spin } from 'move-ui'


type ImagePlaceholderProps = {
  dataTestidSuffix?: string
  forwardRef?: LegacyRef<HTMLDivElement>
  className?: string
}


const ImagePlaceholder = ({
  dataTestidSuffix,
  forwardRef,
  className,
}: ImagePlaceholderProps) => (
  <div
    ref={forwardRef}
    data-testid={`ImagePlaceholder${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
    className={className}
  >
    <div className={`
      flex items-center justify-center
      bg-brand-foam
      border-2 border-brand-pacific-blue rounded
      w-full h-full
    `}>
      <Spin spinning />
    </div>
  </div>
)


export default ImagePlaceholder
