import React from 'react'

import PlatformDescription from './PlatformDescription'
import NavigationMenu from './NavigationMenu'
import AvatarMenu from './AvatarMenu'


const Header = () => (
  <nav
    data-testid='ClientPageHeader'
    className='bg-brand-white flex border-b border-brand-alto px-2x-big'
  >
    <PlatformDescription className='flex-1' />
    <NavigationMenu />
    <AvatarMenu className='flex items-center h-full' />
  </nav>
)


export default Header
