import React, { useMemo, useCallback, CSSProperties } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'move-ui'
import { MenuInfo } from 'rc-menu/lib/interface'

import styles from './NavigationMenu.module.scss'


const { Item } = Menu

const styleMenu: CSSProperties = {
  width: '250px',
}


const NavigationMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const selectedKeys = useMemo(() => {
    if (location.pathname.startsWith('/client/accounts')) {
      return ['/client/dashboard']
    }

    if (location.pathname.startsWith('/client/streams')) {
      return ['/client/streams']
    }

    return [location.pathname]
  }, [location.pathname])

  const onClick = useCallback(({ key }: MenuInfo) => navigate(key), [navigate])


  return (
    <Menu
      data-testid='NavigationMenu'
      className='uppercase'
      style={styleMenu}
      mode='horizontal'
      selectedKeys={selectedKeys}
      onClick={onClick}
    >
      <Item
        key='/client/dashboard'
        className={styles.MenuItem}
      >
        <div className='flex items-center h-full'>
          Portfolio
        </div>
      </Item>

      <Item
        key='/client/streams'
        className={styles.MenuItem}
      >
        <div className='flex items-center h-full'>
          Streams
        </div>
      </Item>
    </Menu>
  )
}


export default NavigationMenu
