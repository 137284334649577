const roundToPoint = (value?: number, point = 2) => {
  if (!value || Number.isNaN(value)) {
    return 0
  }

  return (Math.round(value * Math.pow(10, point)) / Math.pow(10, point))
}


export default roundToPoint
