/**
 * When we want to show trading related data to our customers
 * we need to be be sure that our trades and aggravated NAVs
 * are all handled and not in progress.
 *
 * We can use the utility functions below
 * in order to determine which is the latest trading info date
 * we can request from our BackEnd APIs.
 */

import format from 'date-fns/format'
import endOfYesterday from 'date-fns/endOfYesterday'
import endOfDay from 'date-fns/endOfDay'
import subYears from 'date-fns/subYears'

import Cookies from 'js-cookie'


const LAST_INTO_DATE_COOKIE_LABEL = 'latestInfoDate'


/**
 * Since some of our trades might not be currently handled
 * it'll be best if we show yesterdays data
 * @return {Date}
 */
export const getDefaultLatestInfoDate = () => endOfYesterday()


/**
 * Make sure that no component will consider handle 1 year old data
 * as recent/current data
 * @return {Date}
 */
export const getOldestPossibleInfoDate = () => subYears(getDefaultLatestInfoDate(), 1)


/**
 * This function should be used when we want to get latest trading info from BackEnd
 * but we're not sure how latest this info should be.
 * E.g.: Since our trading cycle might be inprogress, it'll be better to load latest date for yesterday.
 * @return {Date}
 */
export const getLatestInfoDate = (): Date => {
  const date = endOfDay(new Date(Cookies.get(LAST_INTO_DATE_COOKIE_LABEL)))
  const defaultDate = getDefaultLatestInfoDate()

  if (Number.isNaN(date.getTime()) || date.getTime() >= defaultDate.getTime()) {
    Cookies.remove(LAST_INTO_DATE_COOKIE_LABEL)
    return defaultDate
  }

  return date
}


/**
 * This Function will validate and set whatever `inputDate` user would like to use
 * when he browses through the application.
 * Variable `inputDate` is saved as browser cookie in order to be persistent between page reloads.
 *
 * NB: Please note that settings this `inputDate` will affect all data loaded in the App.
 *
 * @param {String} inputDate User suggested info date
 */
export const setLatestInfoDate = (inputDate: string) => {
  const date = new Date(inputDate)
  if (Number.isNaN(date.getTime())) {
    throw new TypeError(`Input "${inputDate}" is invalid date`)
  }

  const formattedDate = endOfDay(new Date(format(date, 'yyyy-MM-dd')))
  const defaultDate = getDefaultLatestInfoDate()

  if (formattedDate.getTime() > defaultDate.getTime()) {
    throw new RangeError(`Input date "${inputDate}" must be no later than "${format(defaultDate, 'yyyy-MM-dd')}"`)
  }

  Cookies.set(LAST_INTO_DATE_COOKIE_LABEL, format(date, 'yyyy-MM-dd'))
}


/**
 * This handy function will tell if the Application is currently using a user-defined date
 * or the default/expected date when loading App`s data.
 *
 * @return {Boolean}
 */
export const isLatestInfoDateManuallySet = (): boolean => getDefaultLatestInfoDate().getTime() !== getLatestInfoDate().getTime()


/**
 * We expose `setLatestInfoDate()` to `window` global object
 * to make sure Developers can invoke `setLatestInfoDate()` through Chrome console.
 */
// @ts-ignore
window.setLatestInfoDate = setLatestInfoDate
