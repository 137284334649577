import React from 'react'
import { Outlet } from 'react-router-dom'

import LatestInfoDatePanel from './LatestInfoDatePanel'
import Header from './Header'
import Footer from './Footer'


const ClientLayout = () => (
  <div className='flex flex-col h-full'>
    <LatestInfoDatePanel />
    <Header />

    <main
      data-testid='ClientPageMainContent'
      className='flex-1 px-2x-big py-base'
    >
      <Outlet/>
    </main>

    <Footer />
  </div>
)


export default ClientLayout
