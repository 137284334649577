import React, { useMemo } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import startOfYear from 'date-fns/startOfYear'
import { NumberComponent, Spin } from 'move-ui'
import { timeService } from 'move-ui/lib/utils/time.service'
import { numberService } from 'move-ui/lib/utils/number.service'

import { TableGrey } from '@src/components'

import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'
import useGetAccountNav from '@src/api/accounts/hooks/useGetAccountNav'
import useGetAccountCashTransfers from '@src/api/accounts/hooks/useGetAccountCashTransfers'

import { Transaction } from '@src/api/types'

import roundToPoint from '@src/utils/roundToPoint'
import colors from '@src/utils/tailwindColors'


Highcharts.setOptions({
  navigator: { enabled: true },
  scrollbar: { enabled: true },
  accessibility: { enabled: false },
  credits: { enabled: false },
})


/* Make sure chart legend symbol is a circle */
Highcharts.wrap(
  Highcharts.Series.prototype,
  'drawLegendSymbol',
  function drawLegendSymbol(...args) {
    /* @ts-ignore */
    Highcharts.seriesTypes.column.prototype.drawLegendSymbol.apply(this, Array.prototype.slice.call(args, 1))
  },
)


const jan1 = startOfYear(new Date())
const getJan1Nav = (data: [number, number][]) => {
  let nav = 0

  data.some(([timestamp], index) => {
    if (timestamp > jan1.getTime()) {
      nav = data[index - 1]?.[1] || 0
      return true
    } else {
      return false
    }
  })

  return nav
}


type DataSource = {
  name: string
  jan1: number
  ytd: number
}


type PerformanceChartProps = {
  dataTestidSuffix?: string
  className?: string
  accountIds?: string[]
  fromDate?: Date,
  toDate?: Date,
}

const PerformanceChart = ({
  dataTestidSuffix,
  className,
  accountIds,
  fromDate,
  toDate,
}: PerformanceChartProps) => {
  const { reportingCurrency, loggedInUserUuid } = useGetLoggedInUser()

  const {
    accountNavs,
    isLoading: isLoadingAccountNavs,
  } = useGetAccountNav({
    customerUuid: loggedInUserUuid,
    accountIds: accountIds!,
    fromDate: fromDate!,
    toDate: toDate!,
    currency: reportingCurrency,
  }, {
    enabled: Boolean(fromDate && toDate && accountIds && accountIds.length && reportingCurrency && loggedInUserUuid),
  })


  const {
    accountCashTransfers,
    isLoadingAccountCashTransfers,
  } = useGetAccountCashTransfers({
    customerUuid: loggedInUserUuid,
    accountIds: accountIds!,
    startDate: fromDate!,
    endDate: toDate!,
    currency: reportingCurrency,
  }, {
    enabled: Boolean(fromDate && toDate && accountIds && accountIds.length && reportingCurrency && loggedInUserUuid),
  })


  const options = useMemo(() => {
    if (!accountNavs || !accountCashTransfers) {
      return {}
    }

    /**
     * `sharedDatesNav` holds a list of all dates a NAV was calculated for every client`s Bank Account.
     * Please note that different Bank Account might be traded in different time periods (daily, weekly, .etc.)
     * and these different trading time periods reflect in different timestamps for calculated NAVs.
     */
    const sharedDatesNav = accountNavs
      .reduce((dates, { timeseries }) => ([
        ...dates,
        ...timeseries
          .map(([dateString]) => new Date(dateString).getTime()),
      ]), [] as number[])
      .sort((dateTime1, dateTime2) => dateTime1 - dateTime2)
      .filter((date, index, dates) => dates.indexOf(date) === index)


    const sharedDatesCashTransfers = accountCashTransfers
      .map(({ date }) => date.getTime())
      .sort((dateTime1, dateTime2) => dateTime1 - dateTime2)
      .filter((date, index, dates) => dates.indexOf(date) === index)


    const sharedDates = [
      ...sharedDatesNav,
      ...sharedDatesCashTransfers,
    ]
    .sort((dateTime1, dateTime2) => dateTime1 - dateTime2)
    .filter((date, index, dates) => dates.indexOf(date) === index)


    /**
     * Some Bank Accounts trade once per week
     * other Bank Accounts trade once per day.
     * These different time periods make for a different data-points in prop `timeseries`.
     *
     * In order to make sure we correctly sum up all NAVs for every date-time
     * we need to make sure that every Bank Account have a data-point for every date-time.
     */
    const seriesDataNav: [number, number][] = (() => {
      const accountNavsWithCarriedDates = accountNavs
        .map((account) => {
          let lastNavValue = 0

          const timeseries = account.timeseries
            .map(([dateString, nav]) => ([
              new Date(dateString).getTime(),
              nav,
            ]))

          /**
           * Make sure that every Bank Account have a corresponding NAV for every timestamp
           * even if the specific Bank Account NAV did not changed during the specified time period.
           *
           * This "carrying" of previous NAV value is needed later on
           * when we calculate the total NAV for each specific date.
           */
          const carriedTimeseries = sharedDates
            .map((dateTime) => {
              const foundTimeseries = timeseries.find(([timeseriesDateTime]) => timeseriesDateTime === dateTime)
              if (foundTimeseries) {
                lastNavValue = foundTimeseries[1]
                return foundTimeseries
              }

              return [
                dateTime,
                lastNavValue,
              ]
            }) as [number, number][]

          return {
            ...account,
            timeseries: carriedTimeseries,
          }
        })


      /**
       * Make sure we sum up all Bank Account NAVs for every timestamp
       * in order to have a correct umbrella image for a given time period.
       */
      return accountNavsWithCarriedDates
        .reduce((aggregatedTimeseries, { timeseries }) => ([
          ...aggregatedTimeseries,
          ...timeseries,
        ]), [] as [number, number][])

        .reduce((aggregatedTimeseries, [dateTime, nav]) => {
          const foundIndex = aggregatedTimeseries.findIndex(([aggregatedDateTime]) => aggregatedDateTime === dateTime)

          if (foundIndex === -1) {
            aggregatedTimeseries.push([dateTime, nav])
          } else {
            aggregatedTimeseries[foundIndex][1] += nav
          }

          return aggregatedTimeseries
        }, [] as [number, number][])

        /**
         * No need to show cents when presenting NAV
         */
        .map(([dateTime, nav]) => ([
          dateTime,
          roundToPoint(nav),
        ]))
    })()


    const seriesDataCashTransfers: [number, number][] = (() => {
      let lastValue = 0

      const dateToCashTransfer = new Map<number, Transaction[]>()
      accountCashTransfers.forEach((cashTransfer) => {
        const dateKey = cashTransfer.date.getTime()
        const cashTransfers = dateToCashTransfer.get(dateKey) || []
        dateToCashTransfer.set(dateKey, [...cashTransfers, cashTransfer])
      })

      /**
       * Make sure that we'll have a Cash Transfer data point for every data-time
       * regardless if the data-time is related to a Cash Transfer or NAV
       */
      return sharedDates
        .map((date) => {
          let currentValue = lastValue
          const cashTransfers = dateToCashTransfer.get(date)

          if (cashTransfers) {
            currentValue += cashTransfers
              .map(({ quantity, marketValue }) => Math.sign(quantity) * marketValue)
              .reduce((sum, value) => sum + value, 0)
          }

          lastValue = currentValue

          return [
            date,
            currentValue,
          ]
        })
    })()


    const series = [
      {
        name: 'Net Asset Value',
        tooltipName: 'NAV',
        data: seriesDataNav,
        color: colors['brand-move-blue'],
        marker: { enabled: false },
      },
      {
        name: 'Deposits',
        tooltipName: 'Deposits',
        data: seriesDataCashTransfers,
        color: colors['brand-egg-blue'],
        dashStyle: 'dash',
        marker: { enabled: false },
      },
    ]


    const rangeSelectorButtons = [
      {
        type: 'month',
        count: 1,
        text: '1m',
        title: 'View 1 month',
      }, {
        type: 'month',
        count: 3,
        text: '3m',
        title: 'View 3 months',
      }, {
        type: 'month',
        count: 6,
        text: '6m',
        title: 'View 6 months',
      }, {
        type: 'ytd',
        text: 'YTD',
        title: 'View year to date',
      }, {
        type: 'year',
        count: 1,
        text: '1y',
        title: 'View 1 year',
      }, {
        type: 'all',
        text: 'All',
        title: 'View all',
      },
    ]


    const ytdButton = rangeSelectorButtons.find(({ type }) => type === 'ytd')
    const ytdButtonIndex = rangeSelectorButtons.findIndex(({ type }) => type === 'ytd')


    return {
      legend: { enabled: true },

      chart: {
        events: {
          /**
           * Code below aims to make sure range 'YTD' is the initially selected data range.
           *
           * Highcharts have a couple of bugs related to 'YTD' default selection.
           * One of them is addressed here:
           *   https://www.highcharts.com/forum/viewtopic.php?t=21881
           *   https://github.com/highcharts/highcharts/issues/942
           *   https://github.com/highcharts/highcharts/pull/954
           *
           * Another bug is that 'YTD' is selected by default
           * only when the 'load()' event is triggered.
           *
           * Our '<PerformanceChart/>' components triggers 'addSeries()' event
           * but does not trigger 'load()' event, hence the complicated code below.
           */
          addSeries() {
            if (!ytdButton || ytdButtonIndex === -1) {
              return
            }

            /* @ts-ignore */
            const chartDom = this.container as HTMLDivElement
            const pressedRangeButton = chartDom.querySelector('.highcharts-range-selector-group .highcharts-button-pressed text')

            /* Reselect 'YTD' if not the initially selected range */
            if (pressedRangeButton?.innerHTML !== ytdButton.text) {
              setTimeout(() => {
                /* @ts-ignore */
                this.update({
                  rangeSelector: {
                    selected: ytdButtonIndex,
                  },
                })
              }, 100)
            }
          },
        },
      },


      rangeSelector: {
        enabled: true,

        /**
         * More about chart range buttons can be read here:
         *   https://api.highcharts.com/highstock/rangeSelector.buttons
         */
        buttons: rangeSelectorButtons,

        /* We want the default selected button to be `YTD` */
        selected: ytdButtonIndex,
      },


      xAxis: {
        type: 'datetime',
      },


      tooltip: {
        formatter: function formatter() {
          /* @ts-ignore */
          const firstPoint = this.points[0]
          /* @ts-ignore */
          const secondPoint = this.points[1]

          /* @ts-ignore */
          let message = timeService.getDateFormat(new Date(this.x))


          if (firstPoint?.series?.name) {
            if (firstPoint.series.name === series[0].name) {
              message += (
                '<br />' +
                '<b>NAV:</b> ' + reportingCurrency + ' ' +
                (numberService.abbreviateBigNumber(firstPoint.y || 0, 2))
              )
            } else {
              message += (
                '<br />' +
                '<b>' + firstPoint.series.name + ':</b> ' + reportingCurrency + ' ' +
                (numberService.abbreviateBigNumber(firstPoint.y || 0, 2))
              )
            }
          }


          if (secondPoint?.series?.name) {
            message += (
              '<br />' +
              '<b>' + secondPoint.series.name + ':</b> ' + reportingCurrency + ' ' +
              (numberService.abbreviateBigNumber(secondPoint.y || 0, 2))
            )
          }


          return message
        },
        split: true,
        crosshairs: true,
      },

      series,
    }
  }, [accountNavs, accountCashTransfers, reportingCurrency])


  const dataSource = useMemo<DataSource[]>(() => {
    if (!Object.keys(options).length) {
      return []
    }

    /* @ts-ignore */
    return options.series
      .map(({ name, data }) => ({
        name,
        jan1: getJan1Nav(data),
        ytd: data.length ? data[data.length - 1][1] : 0,
      }))
  }, [options])


  const columns = useMemo(() => ([
    {
      title: '',
      dataIndex: 'name',
    },
    {
      title: (
        <div className='text-right'>
          {timeService.getDateFormat(jan1)}
        </div>
      ),
      dataIndex: 'jan1',
      render: (jan1) => (
        <div className='text-right'>
          <NumberComponent value={jan1} />
          {' '}
          {reportingCurrency}
        </div>
      ),
    },
    {
      title: (
        <div className='text-right'>
          {timeService.getDateFormat(toDate)}
        </div>
      ),
      dataIndex: 'ytd',
      render: (ytd) => (
        <div className='text-right'>
          <NumberComponent value={ytd} />
          {' '}
          {reportingCurrency}
        </div>
      ),
    },
  ]), [toDate, reportingCurrency])


  const isLoading = !accountIds?.length || isLoadingAccountNavs || isLoadingAccountCashTransfers


  return (
    <div
      data-testid={`PerformanceChart${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
      className={className}
    >
      {!isLoading && !Object.keys(options).length ? (
        <div className='text-brand-silver text-center italic my-2x-big'>
          (No preview available)
        </div>
      ) : (
        /* @ts-ignore */
        <Spin spinning={isLoading}>
          <HighchartsReact
            constructorType='stockChart'
            highcharts={Highcharts}
            options={options}
          />

          <TableGrey
            className='px-large pb-small'
            rowKey='name'
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={false}
          />
        </Spin>
      )}
    </div>
  )
}


export default PerformanceChart
