import React, { useMemo } from 'react'

import { MAX_SIZE } from './constants'
import useIconConfig from './useIconConfig'


const svgProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  width: '100%',
  height: '100%',
  preserveAspectRatio: 'xMidYMid meet',
}

const getStyle = (props) => {
  const {
    display = 'inline-flex',
    alignSelf = 'center',
    size = '100%',
    marginLeft = 0,
    marginRight = 0,
    marginTop = 0,
    marginBottom = 0,
    style = {},
  } = props

  return {
    width: size,
    height: size,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    display,
    alignSelf,
    ...style,
  }
}

const Icon = (props) => {
  const {
    display,
    alignSelf,
    color,
    name,
    title = '',
    showTitle = false,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    size,
    cdnEnvironment = 'production',
    ...htmlAttrs
  } = props

  const {
    width = MAX_SIZE,
    height = MAX_SIZE,
    paths,
    rects,
    attrs,
    defsInnerHtml,
  } = useIconConfig(name, cdnEnvironment)

  const style = getStyle(props)

  const defsInnerHtmlObject = useMemo(() => ({ __html: defsInnerHtml }), [defsInnerHtml])

  if (!paths) {
    return null
  }

  return (
    <span {...htmlAttrs} style={style}>
      <svg {...svgProps} viewBox={`0 0 ${width} ${height}`}>
        {title || showTitle ? (
          <title>{title || htmlAttrs.title || name}</title>
        ) : null}

        {/* Please note that any usage of `<rect/>` needs to be rendered before `<path/>` */}
        {rects.map((attributes, index) => <rect key={index} {...attributes}/>)}

        {paths.map((path, index) => {
          const pathAttrs = attrs[index] || {}

          // @ts-ignore
          if (!pathAttrs?.color && color) {
            // @ts-ignore
            pathAttrs.color = color
          }

          return (
            <path
              key={index}
              d={path}
              {...pathAttrs}
            />
          )
        })}


        {defsInnerHtml ? (
          <defs dangerouslySetInnerHTML={defsInnerHtmlObject} />
        ) : null}
      </svg>
    </span>
  )
}


export default Icon
