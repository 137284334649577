import React, { useMemo } from 'react'

import { Icon } from '@src/components'

import { ProductAssetType } from '@src/api/types'


const wealthIntelligenceUrl = 'https://md.move-digital.ch'


type MarketSecurityLinkProps = {
  dataTestidSuffix?: string
  className?: string
  security: {
    name: string
    isin: string
    ticker: string
    productAssetType: ProductAssetType
  }
}


const MarketSecurityLink = ({
  dataTestidSuffix,
  className,
  security,
}: MarketSecurityLinkProps) => {
  const href = useMemo(() => {
    if (security.productAssetType === ProductAssetType.BOND) {
      return `${wealthIntelligenceUrl}/bonds/${security.isin}`
    }

    return `${wealthIntelligenceUrl}/companies/${security.isin}`
  }, [security])


  /* ISIN can be empty when transaction is forex exchange, e.g. "FX USD:EUR|23/Feb/21" */
  if (!security.isin) {
    return (
      <div
        data-testid={`MarketSecurityLink${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
        className={className}
      >
        {security.name || security.ticker}
      </div>
    )
  }


  return (
    <div
      data-testid={`MarketSecurityLink${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
      className={className}
    >
      <a
        className={`
          inline-flex
          items-center
          space-x-tiny
          text-brand-navy-blue
          hover:underline
        `}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon
          name='external-link'
          size={12}
        />
        <div>
          {security.name || security.ticker}
        </div>
      </a>
    </div>
  )
}


export default MarketSecurityLink
