import React, { lazy, useEffect } from 'react'

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom'

import { setNavigate } from '@src/api/axiosClient'

import ClientLayout from '@src/layouts/ClientLayout'

import LoadableRoute from './LoadableRoute'
import GuardClientLogin from './GuardClientLogin'


const ClientLoginPage = lazy(() => import('@src/pages/Client/Login'))
const ClientDashboardPage = lazy(() => import('@src/pages/Client/Dashboard'))
const ClientAccountPage = lazy(() => import('@src/pages/Client/Account'))

const ClientStreamsListPage = lazy(() => import('@src/pages/Client/StreamsList'))
const ClientStreamPage = lazy(() => import('@src/pages/Client/Stream'))

const OktaSuccessLoginCallbackPage = lazy(() => import('@src/pages/OktaSuccessLoginCallback'))
const PageNotFound = lazy(() => import('@src/pages/NotFound'))


const Routers = () => {
  const navigate = useNavigate()

  /**
   * Share the `navigate` function with Axios Client
   * so we can use `navigate(...)` in case of BackEnd API issue.
   */
  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])

  return (
    <Routes>
      <Route element={<LoadableRoute/>}>
        <Route
          path='/'
          element={<Navigate to='/login'/>}
        />

        <Route
          path='login'
          element={<ClientLoginPage/>}
        />

        <Route
          path='okta-success-login-callback'
          element={<OktaSuccessLoginCallbackPage/>}
        />

        <Route
          path='client'
          element={<GuardClientLogin/>}
        >
          <Route element={<ClientLayout/>}>
            <Route element={<LoadableRoute/>}>
              <Route
                index
                element={<Navigate to='/client/dashboard'/>}
              />

              <Route
                path='dashboard'
                element={<ClientDashboardPage/>}
              />

              <Route
                path='accounts/:accountId'
                element={<ClientAccountPage/>}
              />

              <Route
                path='streams'
                element={<ClientStreamsListPage/>}
              />

              <Route
                path='streams/:streamId'
                element={<ClientStreamPage/>}
              />
            </Route>

            <Route
              path='*'
              element={<PageNotFound/>}
            />
          </Route>
        </Route>

        <Route
          path='*'
          element={<PageNotFound/>}
        />
      </Route>
    </Routes>
  )
}

const AppRouter = () => (
  <BrowserRouter>
    <Routers/>
  </BrowserRouter>
)

export default AppRouter
