import React, { useState, useEffect } from 'react'
import { Spin } from 'move-ui'

type DelayedFallbackProps = {
  minDelay?: number
}

/**
 * Delays the Loading message so we do not only flash before quickly rendering the content.
 *
 * Credit goes to
 *    https://stackoverflow.com/a/58971161
 *
 * @param {object} props List of Component props:
 *   {number} [optional] `minDelay` defaults to 300 milliseconds - How much time to wait before showing a Loading message
 * @return {object} Loading React component
 */
const DelayedFallback = ({
  minDelay = 300,
}: DelayedFallbackProps) => {
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShowLoading(true), minDelay)
    return () => clearTimeout(timeout)
  }, [minDelay])

  return showLoading ? (
    <div className='flex items-center justify-center h-full'>
      <Spin spinning />
    </div>
  ) : null
}


export default DelayedFallback
