import { AxiosResponse } from 'axios'

/**
 * When imported, `joiful` throws with:
 *    ReferenceError: Can't find variable: TextEncoder
 *
 *  So we need a polyfill for `TextEncoder` as discussed here:
 *     https://github.com/sideway/joi/issues/2141#issuecomment-558429490
 */
import 'text-encoding-polyfill'
import * as jf from 'joiful'
import { Constructor } from 'joiful/core'

import ApiResponseValidationError from './ApiResponseValidationError'


type ValidateResponseOptions = {
  isArray: boolean
}


const validateResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Class: any,
  options: ValidateResponseOptions = { isArray: false },
) => async (response: AxiosResponse) => {
  const validate = options.isArray ? jf.validateArrayAsClass : jf.validateAsClass
  const { value, error } = validate(response.data, Class as Constructor<unknown>, {
    abortEarly: false,
    allowUnknown: true,
    stripUnknown: {
      arrays: false,
      objects: true,
    },
  })

  if (error) {
    const { config } = response

    throw new ApiResponseValidationError(
      `\n\t${config.method?.toLocaleUpperCase()} ${config.baseURL || ''}/${config.url}` +
      `\n\tfailed because it\`s response has these validation errors:` +
      `\n\t\t${error.message.replace(/, /g, ',\n\t\t\t').replace(/\. "/g, '.\n\t\t"')}`,
    )
  }

  if (options.isArray) {
    return value as typeof Class[]
  }
  return value as typeof Class
}


export default validateResponse
