import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { NumberComponent } from 'move-ui'
import { timeService } from 'move-ui/lib/utils/time.service'

import { YearToDatePerformancePercentage } from '@src/components'

import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'
import useGetLoggedInUserRelationshipManager from '@src/api/loggedInUser/hooks/useGetLoggedInUserRelationshipManager'
import useGetLoggedInUserAccounts from '@src/api/accounts/hooks/useGetLoggedInUserAccounts'
import useGetAccountDetails from '@src/api/accounts/hooks/useGetAccountDetails'

import { DEFAULT_MOVE_TAGS } from '@src/api/accounts/constants'

import getStrategyName from '@src/utils/getStrategyName'
import { getLatestInfoDate } from '@src/utils/latestInfoDate'


type KeyInformationPanelProps = {
  dataTestidSuffix?: string
  className?: string
  accountIds?: string[]
}


const KeyInformationPanel = ({
  dataTestidSuffix,
  className,
  accountIds: inputAccountIds = [],
}: KeyInformationPanelProps) => {
  const { reportingCurrency, loggedInUserUuid } = useGetLoggedInUser()
  const { accountIds: userAccountIds, accounts: userAccounts } = useGetLoggedInUserAccounts()

  const accountIds = useMemo(() => (
    inputAccountIds
      .filter((accountId) => userAccountIds.includes(accountId))
      .sort()
  ), [inputAccountIds, userAccountIds])


  const initiationDateTime = useMemo(() => (
    userAccounts
      .filter(({ MoveAccountGroupId }) => accountIds.includes(MoveAccountGroupId))
      .map(({ MoveFunded }) => MoveFunded)
      .filter((MoveFunded) => MoveFunded)
      .filter((MoveFunded) => String(MoveFunded) !== 'Invalid Date')
      .map((MoveFunded) => MoveFunded ? MoveFunded.getTime() : new Date().getTime())
      .sort()
      .shift()
  ), [userAccounts, accountIds])


  const latestInfoDate = useMemo(() => getLatestInfoDate(), [])


  const {
    accountDetails,
  } = useGetAccountDetails({
    customerUuid: loggedInUserUuid,
    accountIds,
    currency: reportingCurrency,
  }, {
    enabled: Boolean(accountIds.length && loggedInUserUuid && reportingCurrency),
  })


  const firstAccountDetails = useMemo(() => accountDetails[0], [accountDetails])

  const totalNav = useMemo(() => {
    if (!accountDetails) {
      return 0
    }

    return accountDetails
      .reduce((sum, { quotes }) => sum + (quotes?.nav || 0), 0)
  }, [accountDetails])


  const title = useMemo(() => (
    accountIds.length === 1
      ? getStrategyName(firstAccountDetails)
      : 'Mandate Key Information'
  ), [accountIds, firstAccountDetails])


  const navigationTitle = useMemo(() => (
    accountIds.length === 1
      ? (firstAccountDetails?.book.MoveTags || DEFAULT_MOVE_TAGS)
      : ''
  ), [accountIds, firstAccountDetails])


  const {
    relationshipManagerPhoneNumber,
    relationshipManagerEmail,
  } = useGetLoggedInUserRelationshipManager()

  const mergedClassName = twMerge('bg-white rounded-xl py-base', className)


  return (
    <div className={mergedClassName}>
      <div className='flex items-center justify-between px-big pb-base'>

        {navigationTitle ? (
          <div className='space-y-small'>
            <div className='text-brand-pacific-blue font-bold'>
              <Link
                className='hover:underline'
                to='/client/dashboard'
              >
                Mandate
              </Link>
              {' > '}
              <span>
                {navigationTitle || <br />}
              </span>
            </div>

            <div
              data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.title`}
              className='font-semibold text-3xl'
            >
              {title || <br />}
            </div>
          </div>
        ) : (
            <div
              data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.title`}
              className='font-semibold text-3xl'
            >
              {title || <br />}
            </div>
        )}


        <div
          data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.amount`}
          className='text-brand-navy-blue text-3xl'
        >
          {totalNav ? (
            <>
              <div>
                <span data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.amount.currency`}>
                  {reportingCurrency}
                </span>
                {' '}
                <span
                  data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.amount.notional`}
                  className='font-semibold'
                >
                  <NumberComponent decimals={0} value={totalNav}/>
                </span>
              </div>

              <YearToDatePerformancePercentage
                className='flex'
                accountIds={accountIds}
              />
            </>
          ) : null}
        </div>
      </div>

      <div className='flex border-t border-brand-alabaster space-x-big pt-base px-big'>
        <div>
          <div className='text-brand-dull uppercase'>
            Initiation
          </div>
          <div
            data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.initiationDate`}
            className='font-bold'
          >
            {initiationDateTime ? timeService.getDateFormat(new Date(initiationDateTime)) : ' '}
          </div>
        </div>

        <div>
          <div className='text-brand-dull uppercase'>
            As of
          </div>
          <div
            data-testid={`KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.asOfDate`}
            className='font-bold'
          >
            {timeService.getDateFormat(latestInfoDate)}
          </div>
        </div>

        <div className='flex-1' />

        <div>
          <div className='text-brand-dull uppercase'>
            Relationship manager
          </div>
          <div className='flex space-x-base font-bold'>
            <a
              data-testid={
                `KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.loggedInUserRelationshipManager.email`
              }
              className='font-bold hover:text-brand-black hover:underline'
              href={`mailto:${relationshipManagerEmail}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {relationshipManagerEmail}
            </a>
            <div>
              {' | '}
            </div>
            <div data-testid={
              `KeyInformationPanel${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}.loggedInUserRelationshipManager.phone`
            }>
              {relationshipManagerPhoneNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default KeyInformationPanel
