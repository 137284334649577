import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { Spin } from 'move-ui'

import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'


const GuardClientLogin = () => {
  const { loggedInUser, isLoading } = useGetLoggedInUser()
  const location = useLocation()

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-full'>
        <Spin spinning />
      </div>
    )
  }

  if (!loggedInUser) {
    return <Navigate to='/login' state={{ from: location }}/>
  }

  // TODO: Here we can check account permissions, roles, privileges

  return <Outlet/>
}


export default GuardClientLogin
