import type { SvgDimensions } from './types'
import { MAX_SIZE } from './constants'

const parser = new DOMParser()

/**
 * Returns all `viewBox` props of an SVG
 *   https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/viewBox
 *
 * @param {String} svgText SVG text code
 * @return {SvgDimensions} Rectangle dimensions for the SVG icon
 */
const getSvgDimensions = (svgText = ''): SvgDimensions => {
  const doc = parser.parseFromString(svgText, 'application/xml')
  const viewBox = doc.querySelector('svg')?.getAttribute('viewBox')

  const [minX, minY, width, height] = String(viewBox || '').split(' ')

  return {
    minX: Number.parseFloat(minX) || 0,
    minY: Number.parseFloat(minY) || 0,
    width: Number.parseFloat(width) || MAX_SIZE,
    height: Number.parseFloat(height) || MAX_SIZE,
  }
}

export default getSvgDimensions
