import axios from 'axios'
import { NavigateFunction } from 'react-router-dom'

import queryClient from './queryClient'

/**
 * Function `setNavigate()` will provision navigation instance
 * used when we need to redirect user in case of BackEnd API issue.
 */
let navigate: NavigateFunction | undefined
export function setNavigate(newNavigate: NavigateFunction) {
  navigate = newNavigate
}

const axiosClient = axios.create({
  baseURL: '/',
})


axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    /* Automatically logout when BackEnd says that User is no longer authorized. */
    if (error?.response?.status === 401) {
      queryClient.clear()

      /* Redirect to Login page if user is not already there */
      if (!window.location.pathname.startsWith('/login')) {
        if (navigate) {
          navigate('/login', { state: { from: { pathname: window.location.pathname } } })
        } else {
          window.location.href = '/login'
        }
      }
    }

    return Promise.reject(error)
  },
)

export default axiosClient
