import Highcharts from 'highcharts'

const FIRST_COLOR = '#34617C'
const MAX_BRIGHTNESS = 0.6


/**
 * Takes RGB color string value and
 * return HEX color string value
 *
 * @param {String} rgb Common RGB color expression, e.g. "(127,11,4)"
 * @return {String} HEX representation of the income `rgb` parameter, e.g. "#7FB4"
 */
const convertRgbToHex = (rgb = '') => {
  const regExp = /\(([^)]+)\)/
  const matches = regExp.exec(String(rgb || ''))

  if (!matches || !matches[1]) {
    return '#000000'
  }

  const data = matches[1]
    .split(',')
    .map((colorPart) => colorPart.trim())
    .map((colorPart) => Number.parseInt(colorPart, 10))
    .map((colorPart) => colorPart.toString(16))
    .join('')
    .toLocaleUpperCase()

  return `#${data}`
}


/**
 * This function will take an initial color
 * and based on maximum brightness
 * the function will return a list of brighter color shades
 *
 * @param {Number} totalColors How many colors there should be in the palette
 * @param {String} firstColor [Default: '#34617C'] HEX value of the first (darkest) color in the palette
 * @param {Number} maxBrightness [Default: 0.6] How bright should be the last color
 * @return {String[]} List of shade colors
 */
const getColorShades = (
  totalColors: number,
  firstColor = FIRST_COLOR,
  maxBrightness = MAX_BRIGHTNESS,
): string[] => {
  /* Having only 2 colors makes for a too high contrast */
  const totalColorsIncrement = totalColors === 2 ? 1 : 0
  const maxTotalColors = totalColors + totalColorsIncrement

  const colors = Array
    .from({ length: maxTotalColors })
    .map((_, index) => Highcharts
      .color(firstColor)
      .brighten(maxBrightness * index / (maxTotalColors - 1))
      .get()
      .toString())
    .map(convertRgbToHex)

  colors.length = totalColors

  return colors
}


export default getColorShades
