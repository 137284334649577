import qs from 'qs'
import JSON5 from 'json5'
import format from 'date-fns/format'

import { getLatestInfoDate } from '@src/utils/latestInfoDate'

import axiosClient from '../axiosClient'
import validateResponse from '../validateResponse'
import { Transaction } from '../types'

import {
  DEFAULT_CURRENCY,
  DEFAULT_MAXIMUM_TRANSACTIONS,
} from './constants'

import {
  LoggedInUserAccountsParams,
  Account,

  AccountNavParams,
  AccountNav,

  AccountDetailsParams,
  AccountDetails,

  AccountTransactionsParams,

  AccountCashTransfersParams,

  AccountHistoricalAllocationParams,
  AccountHistoricalAllocation,
} from './types'


const routes = {
  getLoggedInUserAccounts: async (
    params: LoggedInUserAccountsParams,
  ): Promise<Account[]> => {
    const queryString = qs.stringify({
      owner: params.uuid,
      middleOfficeApiUrl: '/public/get/accounts',
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(Account, { isArray: true }))
  },


  getAccountNav: async (
    params: AccountNavParams,
  ): Promise<AccountNav[]> => {
    const middleOfficeParams = {
      id: params.accountIds.join(','),
      from: format(params.fromDate, 'yyyy-MM-dd'),
      to: format(params.toDate, 'yyyy-MM-dd'),
      ccy: params.currency || DEFAULT_CURRENCY,
    }

    const queryString = qs.stringify({
      owner: params.customerUuid,
      middleOfficeApiUrl: '/public/get/account-nav',
      params: JSON5.stringify(middleOfficeParams),
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(AccountNav, { isArray: true }))
  },


  getAccountDetails: async (
    params: AccountDetailsParams,
  ): Promise<AccountDetails[]> => {
    const middleOfficeParams = {
      accountIds: params.accountIds.join(','),
      date: format(params.asOfDate || getLatestInfoDate(), 'yyyy-MM-dd'),
      currency: params.currency || DEFAULT_CURRENCY,
    }

    const queryString = qs.stringify({
      owner: params.customerUuid,
      middleOfficeApiUrl: '/public/get/account-details',
      params: JSON5.stringify(middleOfficeParams),
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(AccountDetails, { isArray: true }))
  },


  getAccountTransactions: async (
    params: AccountTransactionsParams,
  ): Promise<Transaction[]> => {
    const middleOfficeParams = {
      accountId: params.accountId,
      from: format(params.fromDate, 'yyyy-MM-dd'),
      to: format(params.toDate, 'yyyy-MM-dd'),
      currency: params.currency || DEFAULT_CURRENCY,
      maximumTransactions: params.maximumTransactions || DEFAULT_MAXIMUM_TRANSACTIONS,
      shouldIncludeCash: params.shouldIncludeCash,
    }

    const queryString = qs.stringify({
      owner: params.customerUuid,
      middleOfficeApiUrl: '/public/get/account-transactions',
      params: JSON5.stringify(middleOfficeParams),
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(Transaction, { isArray: true }))
  },


  getAccountCashTransfers: async (
    params: AccountCashTransfersParams,
  ): Promise<Transaction[]> => {
    const middleOfficeParams = {
      accountIds: params.accountIds.join(','),
      startDate: format(params.startDate, 'yyyy-MM-dd'),
      endDate: format(params.endDate, 'yyyy-MM-dd'),
      currency: params.currency || DEFAULT_CURRENCY,
    }

    const queryString = qs.stringify({
      owner: params.customerUuid,
      middleOfficeApiUrl: '/public/get/account-cash-transfers',
      params: JSON5.stringify(middleOfficeParams),
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(Transaction, { isArray: true }))
  },

  getAccountHistoricalAllocation: async (
    params: AccountHistoricalAllocationParams,
  ): Promise<AccountHistoricalAllocation[]> => {
    const middleOfficeParams = {
      id: params.accountId,
      from: format(params.fromDate, 'yyyy-MM-dd'),
      to: format(params.toDate, 'yyyy-MM-dd'),
      ccy: params.currency || DEFAULT_CURRENCY,
    }

    const queryString = qs.stringify({
      owner: params.customerUuid,
      middleOfficeApiUrl: '/public/get/account-historical-allocation',
      params: JSON5.stringify(middleOfficeParams),
    })

    return axiosClient
      .get(`api/mo?${queryString}`)
      .then(validateResponse(AccountHistoricalAllocation, { isArray: true }))
  },
}


export default routes
