import { useState, useEffect } from 'react'

import type { Icon } from './types'
import { CDN_ENVIRONMENT_TO_URL, CACHE_TIME_IN_MILLISECONDS } from './constants'
import getSvgDimensions from './getSvgDimensions'
import getSvgObjects from './getSvgObjects'


/**
 * Create an internal temporary cache
 * for all loaded icons
 */
const iconCache = new Map()


const emptyIcon: Icon = {
  width: 0,
  height: 0,
  paths: [],
  rects: [],
  attrs: [],
  defsInnerHtml: '',
}

/**
 * This hook loads icoMoon from CDN location and makes it available for the FrontEnd
 *
 * @param {String} iconName Uniquely describes the icon we want to load
 * @param {String} [cdnEnvironment='production'] Which CDN location we'd like to fetch our icons from; could be either 'test' or 'production'
 * @return {Object}
 */
const useIconConfig = (
  iconName = '',
  cdnEnvironment: 'production' | 'test' = 'production',
): Icon => {
  const [icon, setIcon] = useState<Icon>(emptyIcon)


  useEffect(() => {
    const url = `${CDN_ENVIRONMENT_TO_URL[cdnEnvironment]}/icons/${iconName}.svg`


    /* Check if we can get the preloaded Icon from local cache */
    let cachedIcon = iconCache.get(url)

    if (cachedIcon && cachedIcon.expirationDateTime <= Date.now()) {
      iconCache.delete(url)
      cachedIcon = undefined
    }

    if (cachedIcon) {
      setIcon(cachedIcon.icon)
      return
    }


    const abortController = new AbortController()
    fetch(url, { signal: abortController.signal })
      .then((response) => response.text())
      .then((svgText) => {
        const fetchedIcon = {
          ...getSvgDimensions(svgText),
          ...getSvgObjects(svgText),
        }

        iconCache.set(
          url,
          {
            icon: fetchedIcon,
            /* Leverage on internal cache on top of API cache */
            expirationDateTime: new Date().getTime() + CACHE_TIME_IN_MILLISECONDS,
          },
        )

        setIcon(fetchedIcon)
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          /**
           * API call was aborted.
           * Can happen when user navigates out of a page before all icons were loaded.
           */
          return
        }
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch icon ${iconName}: ${error.message}`)
        setIcon(emptyIcon)
      })


    return () => abortController.abort()
  }, [iconName, cdnEnvironment])


  return icon
}


export default useIconConfig
