import React, { useMemo, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { motion } from 'framer-motion'
import memoize from 'lodash.memoize'
import { Skeleton } from 'move-ui'
import { numberService } from 'move-ui/lib/utils/number.service'

import { Icon } from '@src/components'

import roundToPoint from '@src/utils/roundToPoint'

import getColorShades from './getColorShades'


const CHART_RADIUS = 220


export type DonutChartProps = {
  dataTestidSuffix?: string
  className?: string
  title?: string
  data?: [string, number, number?][]
  expandableData?: {
    label: string
    data: DonutChartProps['data']
    total: number
  }[]
  totalValue?: number
  labelWeightOffset?: number
  currency?: string
  showValueColumn?: boolean
  isLoading?: boolean
}


const DonutChart = ({
  dataTestidSuffix,
  className,
  title = '',
  data = [],
  expandableData = [],
  totalValue = 0,
  labelWeightOffset = 1,
  currency = '',
  showValueColumn = true,
  isLoading = false,
}: DonutChartProps) => {
  const colors = useMemo(() => getColorShades(data.length), [data.length])


  const options = useMemo(() => ({
    legend: { enabled: false },
    accessibility: { enabled: false },
    credits: { enabled: false },

    title: {
      text: `<b>${currency ? `${currency} ` : ''}${numberService.abbreviateBigNumber(totalValue, 2)}</b>`,
      align: 'center',
      verticalAlign: 'middle',
    },

    chart: {
      type: 'pie',
      width: CHART_RADIUS,
      height: CHART_RADIUS,
    },

    plotOptions: {
      pie: {
        innerSize: '70%',
        slicedOffset: 0,
        borderWidth: 0,
        dataLabels: { enabled: false },
        states: { hover: { halo: { size: 5 } } },
      },
    },

    tooltip: {
      formatter: function tooltipFormatter() {
        // @ts-ignore
        return `<b>${this.point.name}</b>: ${roundToPoint(this.point.y)}%`
      },
    },

    series: [{
      type: 'pie',
      colors,
      data: data.map(([label, value, value2]) => ([label, value * 100, value2])),
    }],
  }), [data, colors, currency, totalValue])


  const [isExpandableDataOpen, setIsExpandableDataOpen] = useState({})
  const toggleExpandableDataOpen = useMemo(() => memoize(
    (name) => () => (
      setIsExpandableDataOpen((state) => ({
        ...state,
        [name]: !state[name],
      }))
    )), [])


  const contentVariants = {
    open: {
      opacity: 1,
      y: 0,
      height: '100%',
      display: 'block',
    },

    closed: {
      opacity: 0,
      y: '-100%',
      height: '0px',
      transitionEnd: {
        display: 'none',
      },
    },
  }


  return (
    <div
      data-testid={`DonutChart${dataTestidSuffix ? `.${dataTestidSuffix}` : ''}`}
      className={className}
    >
      {isLoading ? (
        <div className='flex items-center space-x-base p-small'>
          <Skeleton.Avatar size={CHART_RADIUS} active />
          <Skeleton active />
        </div>
      ) : (
        data.length ? (
          <div className='flex items-center space-x-base'>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />

            <div className='flex items-center flex-1'>
              <div className='w-full'>
                {title ? (
                  <h3 className='font-semibold text-lg text-center mb-base'>
                    {title}
                  </h3>
                ) : null}

                <table className='border-collapse border-t border-brand-gallery w-full'>
                  <tbody>
                  {data
                    .map(([name, weight, percentage], index) => {
                      const childExpandableData = expandableData.find(({ label }) => label === name)

                      return [
                        <tr key={index}>
                          <td className='border-b border-brand-gallery w-10 h-10'>
                            <div className='flex items-center justify-center'>
                              <div
                                className='rounded-full w-4 h-4'
                                style={{ backgroundColor: String(colors[index]) }}
                              />
                            </div>
                          </td>

                          <td className='border-b border-brand-gallery'>
                            {weight && childExpandableData?.data?.length ? (
                              <div
                                className='cursor-pointer'
                                onClick={toggleExpandableDataOpen(name)}
                              >
                                {name}
                                {' '}
                                <Icon
                                  name={isExpandableDataOpen[name] ? 'chevron-down' : 'chevron-left'}
                                  size={12}
                                  color={colors['brand-chestnut']}
                                />
                              </div>
                            ) : (
                              name
                            )}
                          </td>

                          {showValueColumn ? (
                            <td className='font-semibold border-b border-brand-gallery text-right'>
                              {currency ? currency + ' ' : null}{numberService.abbreviateBigNumber(percentage, 2)}
                            </td>
                          ) : null}

                          <td className='font-semibold border-b border-brand-gallery text-right pl-small'>
                            {roundToPoint(weight * labelWeightOffset * 100)}%
                          </td>
                        </tr>,

                        <tr key={`expandable_${index}`}>
                          <td
                            className='overflow-hidden p-0'
                            colSpan={showValueColumn ? 4 : 3}
                          >
                            <motion.div
                              initial='closed'
                              animate={isExpandableDataOpen[name] ? 'open' : 'closed'}
                              variants={contentVariants}
                            >
                              <DonutChart
                                dataTestidSuffix={`${title}.${name}`}
                                className='pt-base'
                                title={`${title}: ${name}`}
                                data={childExpandableData?.data}
                                totalValue={weight ? weight * totalValue : 0}
                                labelWeightOffset={weight}
                                currency={currency}
                                showValueColumn={false}
                                isLoading={isLoading}
                              />
                            </motion.div>
                          </td>
                        </tr>,
                      ]
                    })
                  }
                  </tbody>
                </table>

                {/**
                 * Normally the title component on top of the table will push the table a bit down.
                 * This will make the donut chart not vertically aligned to the table.
                 * In order to make sure that table and donut chart are always vertically aligned
                 * we'll place another "invisible" title just below the table.
                 * This "invisible" title have the same height as the top title.
                 */}
                {title ? (
                  <h3 className='invisible font-semibold text-lg text-center mb-base'>
                    {title}
                  </h3>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <h3 className='text-brand-silver text-center'>
            (No {title} data available)
          </h3>
        )
      )}
    </div>
  )
}


export default DonutChart
