import * as jf from 'joiful'

import { objectOfClass } from '@src/api/commonValidationDecorators'


export class LoggedInUser {
  @jf.string().required()
  uuid: string

  @jf.string().required()
  abbr: string

  @jf.string().required()
  reportingCurrency: string

  @jf.date().required()
  created: Date

  @jf.date().allow(null).optional()
  deleted?: Date | null
}


class RelationshipManagerPhone {
  @jf.number().min(1).max(1000)
  countryCode: number

  @jf.string().required()
  nationalNumber: string
}


class RelationshipManagerAddress {
  @jf.string().required()
  canton: string

  @jf.string().required()
  country: string

  @jf.string().required()
  streetName: string

  @jf.string().required()
  streetNumber: string

  @jf.string().required()
  town: string

  @jf.string().required()
  zipCode: string
}


export class LoggedInUserRelationshipManager {
  @jf.string().email({ tlds: { allow: false } }).required()
  email: string

  @objectOfClass(RelationshipManagerPhone)
  phone: RelationshipManagerPhone

  @objectOfClass(RelationshipManagerAddress)
  address: RelationshipManagerAddress
}
