import React from 'react'

import useGetLoggedInUserRelationshipManager from '@src/api/loggedInUser/hooks/useGetLoggedInUserRelationshipManager'


const Footer = () => {
  const {
    loggedInUserRelationshipManager,
    relationshipManagerPhoneNumber,
    relationshipManagerEmail,
  } = useGetLoggedInUserRelationshipManager()

  return (
    <footer
      data-testid='ClientPageFooter'
      className='bg-brand-wedgewood text-sm text-white flex items-center justify-between px-2x-big py-medium'
    >
      {loggedInUserRelationshipManager ? (
        <div className='flex space-x-base'>
          <b>
            Relationship Manager:
          </b>
          {' '}
          <a
            data-testid='ClientPageFooter.loggedInUserRelationshipManager.email'
            className='font-bold hover:text-white hover:underline'
            href={`mailto:${relationshipManagerEmail}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {relationshipManagerEmail}
          </a>
          <div>
            {' | '}
          </div>
          <b data-testid='ClientPageFooter.loggedInUserRelationshipManager.phone'>
            {relationshipManagerPhoneNumber}
          </b>
        </div>
      ) : (
        <b data-testid='ClientPageFooter.loggedInUserRelationshipManager.placeholder'>
          Relationship Manager
        </b>
      )}

      <div>
        <b>
          © {new Date().getFullYear()} MoveDigital Ag.
        </b>
        {' '}
        All rights reserved.
      </div>
    </footer>
  )
}


export default Footer
