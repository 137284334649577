/**
 * Sometimes when we call the BackEnd we get a response data in unexpected format.
 * In these cases we `throw new ApiResponseValidationError('Property "name" is missing from the response')`
 */
class ApiResponseValidationError extends Error {
  /**
   * @param {string} message What is the validation error message we wan to show
   */
  constructor(message: string) {
    super(message)
    this.name = 'API Validation Error'
  }
}


export default ApiResponseValidationError
