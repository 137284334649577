/**
 * This file tracks unhandled FrontEnd errors
 * and sends them to the BackEnd for saving
 * so we can have a better understanding
 * of what kind of issues are triggered by clients.
 */

import routes from '@src/api/errors/routes'


/**
 * In order to keep track of which errors we saved
 * and which errors are newly triggered
 * we keep a record in browser`s session storage.
 * `SESSION_KEY_NAME` is used to point the storage key.
 */
const SESSION_KEY_NAME = 'errorTracker'

/**
 * We want to keep track what kind of errors are seen by our clients
 * but we also do not want to float or servers with duplicates of the same error.
 * `SAVED_ERROR_MAX_SAVED_TIMES` will make sure we send the same error to the server only X amount of times.
 */
const SAVED_ERROR_MAX_SAVED_TIMES = 3

/**
 * If our clients use the app for prolong amount of time (e.g. app stays open in the browser for several days)
 * then we'll need to make sure we do not overflow browser`s memory with old error data.
 * Once a already saved error pass its expiration - then we'll be able to keep saving the same error again.
 */
const SAVED_ERROR_EXPIRATION_IN_HOURS = 24


type SavedError = {
  errorMessage: string
  totalSavedTimes: number
  createdAtIsoTime: string
}


/**
 * In the context of error tracking
 * we basically need to know if a JSON is serializable or not
 * hence the need of handy try-catch function
 *
 * @param {unknown} value Data from session storage
 * @return {SavedError[]} Either the parsed JSON or `null` in case of error
 */
const safeJsonParse = (value: unknown): SavedError[] => {
  try {
    return JSON.parse(String(value))
  } catch {
    return []
  }
}


/* Make sure we always have a valid value saved in the session storage */
const initialSavedErrors = safeJsonParse(window.sessionStorage.getItem(SESSION_KEY_NAME))
if (!initialSavedErrors || !Array.isArray(initialSavedErrors) || !initialSavedErrors.length) {
  window.sessionStorage.setItem(SESSION_KEY_NAME, '[]')
}

let lastErrorMessage = ''


/**
 * This function is called every time when the browser catches a uncaught error.
 * More can be read here:
 *   https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
 *
 * @param {String} message Available as event (sic!) in HTML onerror="" handler
 * @param {String} source URL of the script where the error was raised
 * @param {Number} lineno Line number where error was raised
 * @param {Number} colno Column number for the line where the error occurred
 * @param {Error} error May be null if no corresponding Error Object is available
 * @return {Boolean} If function returns `true` then browser will not fire the default event handler
 */
const onError = (
  message: string | Event,
  source?: string,
  lineno?: number,
  colno?: number,
  error?: Error,
): boolean => {
  const errorMessage = error?.stack || String(message)
  if (!errorMessage) {
    return false
  }


  /**
   * There's a known issue about `onerror` been called twice.
   * More can be read here:
   *   https://github.com/facebook/react/issues/10384#issuecomment-334142138
   */
  if (lastErrorMessage === errorMessage) {
    /* Setting `lastErrorMessage = ''` will skip every 2nd error from handling. */
    lastErrorMessage = ''
    return true
  }
  lastErrorMessage = errorMessage


  const savedErrors: SavedError[] = safeJsonParse(window.sessionStorage.getItem(SESSION_KEY_NAME))
  let savedError = savedErrors.find((savedError) => savedError.errorMessage === errorMessage)

  if (savedError) {
    /* Track record expiration */
    const createdAt = new Date(savedError.createdAtIsoTime)
    if (Date.now() - createdAt.getTime() > SAVED_ERROR_EXPIRATION_IN_HOURS * 60 * 60 * 1000) {
      window.sessionStorage.setItem(SESSION_KEY_NAME, JSON.stringify(
        savedErrors.filter((savedError) => savedError.errorMessage !== errorMessage),
      ))
    }

    if (savedError.totalSavedTimes >= SAVED_ERROR_MAX_SAVED_TIMES) {
      return false
    }
  } else {
    savedError = {
      errorMessage,
      totalSavedTimes: 0,
      createdAtIsoTime: new Date().toISOString(),
    } as SavedError
  }


  routes
    .saveError({ errorMessage: savedError.errorMessage })
    .finally(() => {
      (savedError as SavedError).totalSavedTimes += 1

      window.sessionStorage.setItem(SESSION_KEY_NAME, JSON.stringify([
        savedError,
        ...savedErrors.filter((savedError) => savedError.errorMessage !== errorMessage),
      ]))
    })


  /**
   * Returning `true` will prevent the browser from firing the default event handler.
   * At the moment, we can still show errors in the console.
   */
  return false
}


window.onerror = onError

export {}
