import React from 'react'
import { QueryClientProvider } from 'react-query'

import 'sanitize.css/sanitize.css'
import './index.css'

import queryClient from '@src/api/queryClient'
import '@src/utils/errorTracker'

import ValidateEnvVars from './ValidateEnvVars'
import AppRouter from './AppRouter'
import Devtools from './Devtools'


const App = () => (
  <ValidateEnvVars>
    <QueryClientProvider client={queryClient}>
      <AppRouter/>
      <Devtools/>
    </QueryClientProvider>
  </ValidateEnvVars>
)

export default App
