import React, { useCallback, useState } from 'react'
import { timeService } from 'move-ui/lib/utils/time.service'

import { isLatestInfoDateManuallySet, getLatestInfoDate, setLatestInfoDate } from '@src/utils/latestInfoDate'

import SetLatestInfoDateModal from './SetLatestInfoDateModal'


const LatestInfoDatePanel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = useCallback(() => setIsModalVisible(true), [])
  const hideModal = useCallback(() => setIsModalVisible(false), [])

  const [isLoading, setIsLoading] = useState(false)

  const onConfirm = useCallback((date) => {
    setIsLoading(true)
    setLatestInfoDate(date)
    window.location.reload()
  }, [])

  if (!isLatestInfoDateManuallySet()) {
    return null
  }

  return (
    <div className='bg-brand-valencia text-brand-white text-center p-small'>
      You are currently viewing the App data as it was in
      {' '}
      &quot;{timeService.getDateFormat(getLatestInfoDate())}&quot;.
      {' '}
      <span
        data-testid='LatestInfoDatePanel.modalLink'
        className='cursor-pointer hover:underline'
        onClick={showModal}
      >
        [Click here]
      </span>
      {' '}
      to change it.


      <SetLatestInfoDateModal
        visible={isModalVisible}
        onConfirm={onConfirm}
        onClose={hideModal}
        isLoading={isLoading}
      />
    </div>
  )
}


export default LatestInfoDatePanel
