import { useMemo } from 'react'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { Notification } from 'move-ui'

import { AxiosApiResponseError } from '@src/api/types'
import useGetLoggedInUser from '@src/api/loggedInUser/hooks/useGetLoggedInUser'

import routes from '../routes'
import { Account, AccountType } from '../types'


type BaseOptions = UseQueryOptions<Account[], AxiosApiResponseError>
type Options = BaseOptions & {
  onAfterSuccess?: BaseOptions['onSuccess']
  onAfterError?: BaseOptions['onError']
}

type BaseResult = UseQueryResult<Account[], AxiosApiResponseError>
type Result = BaseResult & {
  accounts: Account[]
  accountIds: Account['Code'][]

  cashAccounts: Account[]
  cashAccountIds: Account['Code'][]

  brokerAccounts: Account[]
  brokerAccountIds: Account['Code'][]

  allAccounts: Account[]
  allAccountIds: Account['Code'][]

  initialFundedDate: Date | null
}


const useGetLoggedInUserAccounts = (
  options: Options = {},
): Result => {
  const { loggedInUser } = useGetLoggedInUser()

  const queryResult = useQuery<Account[], AxiosApiResponseError>(
    ['getLoggedInUserAccounts', loggedInUser?.uuid],
    () => routes.getLoggedInUserAccounts({ uuid: loggedInUser!.uuid }),
    {
      onSuccess: (...args) => {
        if (options.onAfterSuccess) {
          options.onAfterSuccess(...args)
        }
      },

      onError: (...args) => {
        const [error] = args
        const backEndErrorMessage = error.response?.data?.error || ''

        Notification.error({
          key: 'getLoggedInUserAccounts',
          message: `Loading error ${error.response?.statusText || ''}`,
          description: `We could not get your Accounts. ${backEndErrorMessage}`,
          duration: 7,
        })

        if (options.onAfterError) {
          options.onAfterError(...args)
        }
      },

      /* We can safely cache Accounts data for longer than usual since it's based on historical records that do not commonly change */
      cacheTime: 30 * 60 * 1000,
      staleTime: 30 * 60 * 1000,

      ...options,

      enabled: (options.enabled === undefined ? true : options.enabled) && Boolean(loggedInUser?.uuid),
    })


  const allAccounts = queryResult.data || []
  const allAccountIds = allAccounts.map(({ Code }) => Code)

  const cashAccounts = allAccounts.filter((account) => account.AccountType === AccountType.CASH_ACCOUNT)
  const cashAccountIds = cashAccounts.map(({ Code }) => Code)

  const brokerAccounts = allAccounts.filter((account) => account.AccountType === AccountType.BROKER_ACCOUNT)
  const brokerAccountIds = brokerAccounts.map(({ Code }) => Code)


  const initialFundedDate = useMemo(() => {
    if (!brokerAccounts.length) {
      return null
    }

    const initialFundedTime = brokerAccounts
      .map(({ MoveFunded }) => MoveFunded)
      .filter((MoveFunded) => MoveFunded)
      .filter((MoveFunded) => String(MoveFunded) !== 'Invalid Date')
      .map((MoveFunded) => MoveFunded ? MoveFunded.getTime() : new Date().getTime())
      .sort()
      .shift()

    if (!initialFundedTime) {
      return null
    }

    return new Date(initialFundedTime)
  }, [brokerAccounts])


  return {
    ...queryResult,

    accounts: brokerAccounts,
    accountIds: brokerAccountIds,

    cashAccounts,
    cashAccountIds,

    brokerAccounts,
    brokerAccountIds,

    allAccounts,
    allAccountIds,

    initialFundedDate,
  }
}


export default useGetLoggedInUserAccounts
