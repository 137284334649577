import * as jf from 'joiful'


export class LogoutResponse {
  @jf.string().min(10).max(100).required()
  code: string

  @jf.string().min(10).required()
  message: string
}
